<template>
  <div class="patient-card patient-card-component">
    <div
      class="card"
      @click="handleClick"
    >
      <router-link :to="{ name: routerPath, params: { id: patient.Id } }">
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <div class="is-profile-picture-64x64">
                  <img
                    :src="patient.Photo || utilities.getDefaultProfilePicture(patient.Gender)"
                    alt="Foto do paciente"
                  >
                </div>
              </figure>
            </div>
  
            <div class="media-content">
              <div class="content">
                <div class="content-line">
                  <div class="patient">
                    {{ patient.Name }}
                  </div>
  
                  <div class="cid">
                    {{ patient.displayCid }}
                  </div>
                </div>
  
                <div class="detail">
                  {{ getAbbrevGenderSafe(patient.Gender) }}{{ patient.BirthDate ? `, ${getAgeSafe(patient.BirthDate)}` : "" }}
                </div>
  
                <div
                  v-if="patient.PS"
                  class="painLevel"
                >
                  Dor: <strong>{{ patient.PS }}</strong>
                </div>
  
                <!-- <div class="serviceLocation">
                    <strong>{{ patient.attendancePlace }}</strong>
                  </div> -->
              </div>
            </div>
          </article>
        </div>
      </router-link>
    </div>
  </div>
</template>
  
  <script setup lang="ts">
  import { defineProps, defineEmits, onMounted } from 'vue';
  import { FrontendPatient, PatientCID, PatientAttributeOption } from "@/models";
  import { utilities } from "@/plugins/UtilitiesV2";
  
  // Definição das Props usando tipagem genérica
  const props = defineProps<{
    patient: FrontendPatient;
    routerPath?: string;
  }>();
  
  // Definição dos Eventos Emitidos
  const emit = defineEmits<{
    (e: 'customClickEvent', patient: FrontendPatient): void;
  }>();
  
  // Métodos
  
  /**
   * Emite um evento personalizado quando o cartão é clicado.
   */
  const handleClick = () => {
    emit('customClickEvent', props.patient);
  };
  
  /**
   * Corta a mensagem se exceder 75 caracteres.
   * @param message A mensagem a ser cortada.
   * @returns A mensagem cortada ou original.
   */
  const messageSlice = (message?: string | null): string => {
    if (message && message.length > 75) {
      return `${message.substring(0, 75)}...`;
    }
    return message || '';
  };
  
  /**
   * Obtém o CID display a partir da lista de CIDs do paciente.
   * @param cids A lista de CIDs do paciente.
   * @returns Uma string representando os CIDs.
   */
  const getDisplayCid = (cids: PatientCID[] | null): string => {
    if (!cids || cids.length === 0) return '';
    return cids.map(cid => cid.CID.CIDCode).join(', ');
  };
  
  /**
   * Safe wrapper para getAbbrevGender para lidar com undefined ou null
   * @param gender O gênero do paciente
   * @returns Gênero abreviado ou string vazia
   */
  const getAbbrevGenderSafe = (gender?: string | null): string => {
    return utilities.getAbbrevGender(gender ?? '');
  };
  
  /**
   * Safe wrapper para getAge para lidar com undefined ou null
   * @param birthDate A data de nascimento do paciente
   * @returns Idade ou string vazia
   */
  const getAgeSafe = (birthDate?: string | null): string => {
    if (birthDate) {
      return utilities.getAge(birthDate);
    }
    return '';
  };
  
  // Hook do Ciclo de Vida
  onMounted(() => {
    console.log('Props recebidas:', props.patient, props.routerPath);
  });
  </script>
  
  <style scoped>
.patient-card .card {
    border-radius: 6px;
    box-shadow: none;
    margin-bottom: 8px;
    cursor: pointer;
}

.patient-card .card.active {
    border: 1px solid #9A397D;
}

.patient-card .card:last-child {
    margin-bottom: 0px;
}

.patient-card .card .box {
    padding: 16px 12px;
    box-shadow: 0px 3px 6px #0000000D;
}

.patient-card .card .content-line {
    display: flex;
    justify-content: space-between;

    margin-bottom: 18px;
}

.patient-card .card .content-line:first-child {
    margin-top: 5px;
}

.patient-card .card .content-line:last-child {
    margin-bottom: 0px;
}

.patient-card .card .image {
    position: relative;
    margin-top: 15px;
}

.patient-card .card .patient {
    font-size: 17px;
    line-height: 17px;
    font-weight: 700;
    color: #484848;
}

.patient-card .card .cid {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #A8A8A8;
    text-transform: uppercase;
}

.patient-card .card .detail,
.patient-card .card .diagnostic {
    font-size: 14px;
    line-height: 18px;
    color: #484848;
}

.patient-card .card .painLevel,
.patient-card .card .serviceLocation {
    font-size: 13px;
    line-height: 13px;
    color: #484848;
}

.patient-card .card .painLevel strong,
.patient-card .card .serviceLocation strong {
    text-transform: uppercase;
}

.patient-card .card .detail {
    margin-bottom: 5px;
}

.patient-card .card .diagnostic,
.patient-card .card .painLevel {
    margin-bottom: 10px;
}

.content .patient-card figure {
    margin-right: 10px;
    margin-left: 10px;
}
  </style>
  