import { HistoryRecord, HistoryRecordType } from './index';
import { utilities } from '@/plugins/UtilitiesV2';

// Define the FrontendHistoryRecord class implementing the HistoryRecord interface
export class FrontendHistoryRecord implements HistoryRecord {
    Id: number;
    PatientId?: number | null;
    HistoryRecordTypeId?: number | null;
    InitialDate?: string | null;
    EndDate?: string | null;
    HistoryRecordNotes?: string | null;
    HistoryRecordType?: HistoryRecordType;

    // Frontend-specific properties
    deleteLoading: boolean;
    addNew: boolean;

    constructor(data: HistoryRecord & { deleteLoading: boolean; addNew: boolean }) {
        this.Id = data.Id;
        this.PatientId = data.PatientId || null;
        this.InitialDate = data.InitialDate || null;
        this.EndDate = data.EndDate || null;
        this.HistoryRecordNotes = data.HistoryRecordNotes || null;
        this.HistoryRecordType = data.HistoryRecordType || undefined;
        this.HistoryRecordTypeId = data.HistoryRecordType?.Id || null;

        this.deleteLoading = data.deleteLoading;
        this.addNew = data.addNew;
      }

    // Getter for formattedDate (Always show InitialDate, concatenate EndDate if it exists)
    get formattedDate(): string {
        const initialDate = this.InitialDate;
        const endDate = this.EndDate ? ` - ${this.EndDate}` : '';
        return `${initialDate}${endDate}`;
    }

}
