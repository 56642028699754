export enum Feature {
    Desfechos = 0,
    Transferencia = 1,
    ReceitaMedica = 2,
    AdicaoDeEquipes = 3,
    Dashboard = 4,
    BI = 5,
    EnvioDeVideos = 6,
    AcoesDePacientes = 7,
    BarreirasDePacientes = 8,
    TermometroDeDistress = 9,
    Calendario = 10,
    Automacoes = 11,
  }
  