<template>
  <lila-input-wrapper :validation="validation">
    <lila-label :label="label" />

    <lila-control :validation="validation">
      <input
        v-maska:[attrs]
        v-bind="attrs"
        :value="attrs.modelValue"
        class="input"
        @maska="onInput"
      >
    </lila-control>
  </lila-input-wrapper>
</template>

<script setup>
/*
* Build using COMPOSITION API from VUE 3
* The objective of this new API is to be modular, and declarative
* everything which do something in this file, is declared in this file
* no magic or hidden behaiviour
*/
import { vMaska, Mask } from "maska"; // Replacement of vue-the-mask for vue3 https://beholdr.github.io/maska/#/
import { useAttrs, watch } from "vue";

import useInputProps from "@composables/useInputProps.js";
import LilaControl from "@components/shared/fields/LilaControl.vue";
import LilaLabel from "@components/shared/fields/LilaLabel.vue";
import LilaInputWrapper from "@components/shared/fields/LilaInputWrapper.vue";

const p = defineProps({
  ...useInputProps(),
  keepMasked: Boolean
});
const emit = defineEmits(["update:modelValue"]);

const attrs = useAttrs(); // This bind every prop and listenner to the parent (this way no need to declare every single prop and event)

const mask = new Mask(attrs);
watch(
  () => attrs.modelValue,
  (aft, bef) => {
    if (bef) return;
    if (!attrs.modelValue) return;
    if (!mask.completed(aft)) return;
    emit("update:modelValue", mask.masked(aft));
  }
);


function onInput(e) {
  if (!e?.detail)return emit("update:modelValue", null);
  if (p.keepMask) return emit("update:modelValue", e.detail.masked);
  emit("update:modelValue", e.detail.unmasked);
}
</script>
