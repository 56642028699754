<template>
  <div class="history-record-card history-record-card-component">
    <div class="columns is-multiline">
      <div
        v-for="(historyRecord, index) in dataList"
        :key="`${historyRecord.Id}-${index}`"
        class="column"
        :class="cardSize"
      >
        <div class="timeline-container">
          <!-- Data inicial como marco da timeline -->
          <div
            v-if="historyRecord.InitialDate"
            class="timeline-date"
          >
            {{ historyRecord.InitialDate }}
          </div>


          <!-- Linha vertical da timeline -->
          <div class="timeline-line" />

          <!-- Marcador circular -->
          <div class="timeline-marker" />

          <!-- Card de histórico -->
          <div class="card">
            <p class="type">
              {{ historyRecord.HistoryRecordType?.Name || '-' }}
            </p>

            <!-- Exibe a data inicial e, se existir, a data final -->
            <p class="date">
              {{ historyRecord.formattedDate }}
            </p>

            <p class="description">
              {{ descriptionSlice(historyRecord.HistoryRecordNotes) }}
            </p>

            <div class="box-buttons">
              <div class="buttons">
                <button
                  type="button"
                  class="button is-rounded default"
                  @click="clickEditEvent(historyRecord, index)"
                >
                  Editar
                </button>
                <button
                  type="button"
                  class="button is-rounded cancel"
                  :class="historyRecord.deleteLoading ? 'is-loading' : ''"
                  @click="clickDeleteEvent(historyRecord, index)"
                >
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FrontendHistoryRecord } from '@/models';

export default defineComponent({
  name: 'HistoryRecordCard',
  props: {
    dataList: {
      type: Array as () => FrontendHistoryRecord[],
      required: true,
      default: () => [],
    },
    extendedDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardSize: {
      type: String,
      required: false,
      default: 'is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-4-fullhd',
    },
  },
  emits: ['customClickEditEvent', 'customClickDeleteEvent'],
  methods: {
    clickEditEvent(historyRecord: FrontendHistoryRecord, index: number): void {
      this.$emit('customClickEditEvent', historyRecord, index);
    },
    clickDeleteEvent(historyRecord: FrontendHistoryRecord, index: number): void {
      this.$emit('customClickDeleteEvent', historyRecord, index);
    },
    descriptionSlice(historyRecordNotes: string | null | undefined): string {
  if (!historyRecordNotes) {
    return '';
  }

  // No need to replace line breaks with <br>
  const maxLength = this.extendedDescription ? 350 : 120;
  if (historyRecordNotes.length > maxLength) {
    return `${historyRecordNotes.substring(0, maxLength)}...`;
  }

  return historyRecordNotes;
},
    formatDate(date: string | null | undefined): string {
      if (!date) return '';
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
});
</script>

<style scoped>
@import '~@assets/css/components/historyRecordCard.css';
</style>
