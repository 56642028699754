import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-narrow patient-sidebar" }
const _hoisted_3 = {
  ref: "conversationList",
  class: "conversation-list custom-scroll"
}
const _hoisted_4 = { class: "conversation-content" }
const _hoisted_5 = { class: "conversation-list-header patient-list-header" }
const _hoisted_6 = { class: "menu" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "order-by" }
const _hoisted_9 = { class: "filter-by" }
const _hoisted_10 = { ref: "scrollSentinel" }
const _hoisted_11 = { class: "loading small" }
const _hoisted_12 = { class: "last-page has-text-centered" }
const _hoisted_13 = { class: "column patient-column" }
const _hoisted_14 = {
  key: 0,
  class: "wait-patient has-text-centered"
}
const _hoisted_15 = { class: "image" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 1,
  ref: "patientBox",
  class: "patient-box"
}
const _hoisted_18 = { class: "menu-patient-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lila_message = _resolveComponent("lila-message")!
  const _component_lila_button = _resolveComponent("lila-button")!
  const _component_lila_input = _resolveComponent("lila-input")!
  const _component_lila_svgIcon = _resolveComponent("lila-svgIcon")!
  const _component_lila_radio = _resolveComponent("lila-radio")!
  const _component_lila_select = _resolveComponent("lila-select")!
  const _component_lila_patient_card = _resolveComponent("lila-patient-card")!
  const _component_lila_patient_card_summary = _resolveComponent("lila-patient-card-summary")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_lila_cid_modal = _resolveComponent("lila-cid-modal")!
  const _component_lila_default_template = _resolveComponent("lila-default-template")!

  return (_openBlock(), _createBlock(_component_lila_default_template, { "loading-signal-r": _ctx.loadingSignalR }, {
    mainContent: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["container patient-list", _ctx.loadingContent ? 'loading large' : ''])
      }, [
        _cache[19] || (_cache[19] = _createElementVNode("h1", { class: "title" }, " Pacientes ", -1)),
        _createVNode(_component_lila_message, { "alert-data": _ctx.alert }, null, 8, ["alert-data"]),
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: _normalizeClass(!_ctx.$AccessControl.cannot(
                      _ctx.$Session.get(_ctx.$userSessionName).profile,
                      'add_patient'
                    )
                      ? 'box-input-search'
                      : ''
                    )
                  }, [
                    (!_ctx.$AccessControl.cannot(
                        _ctx.$Session.get(_ctx.$userSessionName).profile,
                        'add_patient'
                      )
                      )
                      ? (_openBlock(), _createBlock(_component_lila_button, {
                          key: 0,
                          type: "route",
                          title: "Adicionar",
                          class: "submit-buttons",
                          path: "step1PatientInformation"
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("form", {
                      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.filterBy('byNameOrRegisterNumber')), ["prevent"]))
                    }, [
                      _createVNode(_component_lila_input, {
                        modelValue: _ctx.search.byNameOrRegisterNumber,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search.byNameOrRegisterNumber) = $event)),
                        type: "inputSearch",
                        class: "with-shadow",
                        placeholder: "Pesquisar",
                        title: "Insira o nome ou número de matrícula para pesquisar",
                        readonly: _ctx.loadingInfinityScroll,
                        "btn-clear-search": _ctx.search.activateClearSearchBtn,
                        onCustomClearSearchInputEvent: _cache[1] || (_cache[1] = ($event: any) => (
                          _ctx.clearFilterBy('byNameOrRegisterNumber')
                        ))
                      }, null, 8, ["modelValue", "readonly", "btn-clear-search"])
                    ], 32)
                  ], 2),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.filterByVisible ? 'active' : ''),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openOptions('filterBy')))
                      }, " Filtrar por ", 2)
                    ]),
                    _withDirectives(_createElementVNode("button", {
                      class: "clear-filter",
                      disabled: _ctx.loadingInfinityScroll,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearFilterBy('byCidEstadio')))
                    }, [
                      _createVNode(_component_lila_svgIcon, {
                        src: require('@assets/icons/delete.svg')
                      }, null, 8, ["src"]),
                      _cache[8] || (_cache[8] = _createTextVNode(" Limpar Filtro "))
                    ], 8, _hoisted_7), [
                      [_vShow, _ctx.filterByVisible &&
                        (_ctx.search.activeCid || _ctx.search.activeEstadio)
                      ]
                    ])
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_lila_radio, {
                      label: "",
                      name: "orderBy",
                      options: _ctx.orderByList
                    }, null, 8, ["options"])
                  ], 512), [
                    [_vShow, _ctx.orderByVisible]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_lila_input, {
                      modelValue: _ctx.search.cidCode,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search.cidCode) = $event)),
                      placeholder: "Selecionar CID",
                      class: "filter-by-cid",
                      readonly: true,
                      disabled: _ctx.loadingInfinityScroll,
                      onCustomClickEvent: _ctx.openCidModal
                    }, null, 8, ["modelValue", "disabled", "onCustomClickEvent"]),
                    _createVNode(_component_lila_select, {
                      modelValue: _ctx.search.byEstadio,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search.byEstadio) = $event)),
                      placeholder: "Selecionar Estadio",
                      options: _ctx.estadioList,
                      readonly: _ctx.loadingInfinityScroll,
                      onCustomChangeEvent: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterBy('byEstadio')))
                    }, null, 8, ["modelValue", "options", "readonly"])
                  ], 512), [
                    [_vShow, _ctx.filterByVisible]
                  ])
                ]),
                _createVNode(_component_lila_patient_card, {
                  "data-list": _ctx.data.patientList,
                  "selected-patient": _ctx.data.selectedPatient || {},
                  "router-path": "myPatientChat",
                  onCustomClickEvent: _ctx.selectPatient
                }, null, 8, ["data-list", "selected-patient", "onCustomClickEvent"]),
                _createElementVNode("div", _hoisted_10, null, 512),
                _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
                  [_vShow, _ctx.loadingInfinityScroll]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_12, " - - - ", 512), [
                  [_vShow, _ctx.pagination.lastPage]
                ])
              ])
            ], 512)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.pageName == 'myPatients' || !_ctx.data.selectedPatient)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("figure", _hoisted_15, [
                    _createElementVNode("img", {
                      src: require('@assets/images/doctor.png')
                    }, null, 8, _hoisted_16)
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("p", null, " Clique nos cards ao lado para interagir e para mais informações ", -1))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_lila_patient_card_summary, {
                    patient: _ctx.data.selectedPatient
                  }, null, 8, ["patient"]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientMedicalRecord' ? 'active' : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientMedicalRecord',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Ficha ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientHistoryRecords' ? 'active' : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientHistoryRecords',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode(" Registros ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2),
                      _withDirectives(_createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'mypatientPrescriptions' ? 'active' : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'mypatientPrescriptions',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" Receita Médica ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2), [
                        [_vShow, _ctx.userHasPermission(2)]
                      ]),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientClinicalEvolution'
                      ? 'active'
                      : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientClinicalEvolution',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode(" Evolução Clínica ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientChat' ? 'active' : '')
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientChat',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Conversa ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2),
                      _withDirectives(_createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientBarriers' ? 'active' : '')
                      }, [
                        _createVNode(_component_router_link, {
                          to: { name: 'myPatientBarriers', params: { id: _ctx.data.selectedPatient.Id } }
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode(" Barreiras ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2), [
                        [_vShow, _ctx.userHasPermission(8)]
                      ]),
                      (_ctx.hasAccessToActionFeature())
                        ? (_openBlock(), _createElementBlock("li", {
                            key: 0,
                            class: _normalizeClass(_ctx.$route.name == 'myPatientActions' ? 'active' : '')
                          }, [
                            _createVNode(_component_router_link, {
                              to: { name: 'myPatientActions', params: { id: _ctx.data.selectedPatient.Id } }
                            }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode(" Ações ")
                              ])),
                              _: 1
                            }, 8, ["to"])
                          ], 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientChatHistory' ? 'active' : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientChatHistory',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode(" Conversas Encerradas ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$route.name == 'myPatientAttachments' ? 'active' : ''
                    )
                      }, [
                        _createVNode(_component_router_link, {
                          to: {
                        name: 'myPatientAttachments',
                        params: {
                          id: _ctx.data.selectedPatient.Id,
                        },
                      }
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" Anexos ")
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ], 2)
                    ])
                  ]),
                  _createVNode(_component_router_view, {
                    "closed-ticket-id": _ctx.closedTicketId,
                    "new-message-for-patient": _ctx.newMessageForPatient,
                    onUpdatePatient: _ctx.updatePatient,
                    onUpdatePatientCardSummary: _ctx.updatePatientCardSummary
                  }, null, 8, ["closed-ticket-id", "new-message-for-patient", "onUpdatePatient", "onUpdatePatientCardSummary"])
                ], 512))
          ])
        ], 512), [
          [_vShow, !_ctx.loadingContent]
        ]),
        _createVNode(_component_lila_cid_modal, {
          "modal-active": _ctx.cidModalActive,
          onCustomClickEvent: _ctx.selectCid
        }, null, 8, ["modal-active", "onCustomClickEvent"])
      ], 2)
    ]),
    _: 1
  }, 8, ["loading-signal-r"]))
}