<template>
  <div
    class="messages chat chat-history"
    :class="loadingContent ? 'loading large' : ''"
  >
    <div class="input-search">
      <form @submit.prevent="searchMessage()">
        <lila-input
          v-model="search"
          type="inputSearch"
          class="with-shadow"
          placeholder="Pesquisar na conversa"
          :btn-clear-search="activateClearSearchBtn"
          @custom-clear-search-input-event="clearSearch()"
        />
      </form>
    </div>

    <lila-message :alert-data="alert" />

    <div v-show="!loadingContent">
      <div class="chat-history">
        <div
          v-for="item in data.chatList"
          :key="item.Id"
          class="chat-history-item"
        >
          <div
            v-if="item.Messages.length == 0"
            class="empty-chat"
          >
            <p><span>Conversa iniciada em <time>{{ $Utilities.formatDateWithTime(item.Date, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY - HH:mm:ss') }}</time>. Porem nenhuma mensagem foi encontrada.</span></p>
          </div>

          <div v-else>
            <p><time>{{ $Utilities.formatDateWithTime(item.Messages[0].DateAndTime, 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY - HH:mm:ss') }}</time> <span>{{ item.Messages[0].Message }}</span></p>
            <lila-button
              type="button"
              title="Ver mais"
              class="btn-view-more"
              button-style="purple-border borderless"
              @custom-click-event="openChatHistoryModal(item.Messages[0].TicketId)"
            />
          </div>
        </div>
      </div>

      <lila-pagination
        :pagination-config="pagination"
        @custom-change-page-event="changePage"
      />

      <div class="chat-history-modal default-modal">
        <div
          class="modal "
          :class="modalChatHistoryActive ? 'is-active' : ''"
        >
          <div class="modal-background" />

          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">
                <time>{{ data.selectedMessage.chatDate }}</time>
              </p>
            </header>

            <section
              class="modal-card-body"
              :class="loadingChatHistory ? 'loading large' : ''"
            >
              <div
                ref="messagesListBox"
                class="messages-list custom-scroll"
              >
                <div v-if="data.selectedMessage?.messageList">
                  <lila-chat-message
                    v-for="(item, index) in data.selectedMessage.messageList"
                    :key="`chat-message-${index}`"
                    :message="item"
                    :last-date="index <= 0 ? undefined : data.selectedMessage.messageList[index - 1].DateAndTime"
                  />
                </div>
              </div>
            </section>

            <footer class="modal-card-foot">
              <lila-button
                type="submit"
                title="Fechar"
                class="button"
                :is-centralized="true"
                :loading="loadingChatHistory"
                @custom-click-event="closeChatHistoryModal()"
              />
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";
import Pagination from "@components/shared/pagination/Pagination.vue";
import LilaChatMessage from "@components/Chat/LilaChatMessage.vue";

// import { required, requiredIf } from '@vuelidate/validators';

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-pagination": Pagination,
    LilaChatMessage
  },
  data(){
    return {
      data: {
        patientId: this.$route.params.id,
        ticketId: "",
        chatList: [],
        selectedMessage: {
          chatDate: "",
          messageList: ""
        }
      },
      search: "",
      activateClearSearchBtn: false,
      modalChatHistoryActive: false,
      loadingContent: false,
      loadingChatHistory: false,
      alert: {
        message: "",
        type: "",
        show: false
      },
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10
      }
    };
  },
  created(){
    this.getChatHistoryList();
  },
  methods: {
    getChatHistoryList(page = 1, searchParam = ""){
      this.alert.show = false;
      this.loadingContent = true;
      this.data.chatList = [];
      this.pagination.current = 0;
      this.pagination.totalPages = 0;

      if(this.activateClearSearchBtn) searchParam = this.search;

      this.$axios
        .get(
          `/api/chat/history/patient/${this.data.patientId}?page=${page}&limit=${this.pagination.perPage}${searchParam ? `&search=${searchParam}` : ""}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.data.chatList = res.data.Data;

          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;
          this.pagination.perPage = res.data.Pagination.ItemPerPage;
        })
        .catch(error => {
          if(searchParam) this.closeChatHistoryModal();
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, error.response.status == 404 ? "warning" : "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    getChatHistory(ticketId){
      this.alert.show = false;
      this.loadingChatHistory = true;

      this.$axios
        .get(
          `/api/chat/${ticketId}`,
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.data.selectedMessage.chatDate = this.$Utilities.formatDateWithTime(res.data.StartingDateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY - HH:mm:ss");
          this.data.selectedMessage.messageList = res.data.Messages;
        })
        .catch(error => {
          this.closeChatHistoryModal();

          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingChatHistory = false);
    },
    searchMessage(){
      this.activateClearSearchBtn = true;

      this.getChatHistoryList(1, this.search);
    },
    clearSearch(){
      this.search = "";
      this.activateClearSearchBtn = false;
      this.getChatHistoryList();
    },
    openChatHistoryModal(ticketId){
      this.getChatHistory(ticketId);

      this.modalChatHistoryActive = true;
    },
    closeChatHistoryModal(){
      this.data.selectedMessage.chatDate = "";
      this.data.selectedMessage.messageList = "";
      this.modalChatHistoryActive = false;
    },
    changePage(page){
      this.getChatHistoryList(page);
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/chat.css';
</style>
