<template>
  <lila-login-template>
    <template #mainContent>
      <lila-terms
        :modal-active="activeModal"
        @accept-terms="acceptTerms"
      />

      <form
        class="login-form full-width"
        @submit.prevent="submitForm"
      >
        <h1 class="title">
          Faça seu Cadastro
        </h1>

        <lila-message :alert-data="alert" />

        <lila-input
          v-model="v.data.crm.$model"
          placeholder="CRM"
          :validation="v.data.crm"
        />

        <lila-select
          v-model="v.data.ufcrm.$model"
          placeholder="UF"
          :options="stateList"
          :validation="v.data.ufcrm"
        />

        <lila-input
          v-model="v.data.name.$model"
          placeholder="Nome"
          :validation="v.data.name"
        />

        <lila-input
          v-model="v.data.email.$model"
          placeholder="E-mail"
          :validation="v.data.email"
        />

        <lila-mask
          v-model="v.data.phone.$model"
          placeholder="Telefone"
          :mask="['(##) ####-####', '(##) #####-####']"
          :validation="v.data.phone"
        />

        <lila-checkbox
          v-model="v.data.acceptedTerms.$model"
          title="Li e aceito os termos de uso. <strong>Ver Termos de Uso</strong>"
          class="terms-use"
          :validation="v.data.acceptedTerms"
          @custom-click-event="openTerms"
        />

        <lila-button
          type="submit"
          title="Finalizar"
          class="submit-buttons"
          :loading="loading"
        />
      </form>
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Checkbox from "@components/shared/fields/Checkbox.vue";
import Terms from "@components/shared/lila/Terms.vue";
import Message from "@components/shared/message/Message.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";

import { required, email, maxLength } from "@vuelidate/validators";

import StateOptions from "@assets/options/states.json";

const verifyLastName = function(value){
  let name = value.trim().replace(/\s\s+/g, " ").split(" ");

  if(typeof name[1] == "undefined" || (typeof name[1] != "undefined" && name[1].length < 1)) return false;

  return true;
};

export default {
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-checkbox": Checkbox,
    "lila-terms": Terms,
    "lila-message": Message,
    "lila-login-template": LoginTemplate,
    "lila-mask": LilaMask
  },
  data() {
    return {
      data: {
        name: "",
        crm: "",
        ufcrm: "",
        email: "",
        phone: "",
        acceptedTerms: false
      },
      activeModal: false,
      stateList: StateOptions.options,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        name: {
          required,
          verifyLastName,
          maxLength: maxLength(255)
        },
        crm: {
          required,
          maxLength: maxLength(10)
        },
        ufcrm: {
          required,
          maxLength: maxLength(25)
        },
        email: {
          required,
          email,
          maxLength: maxLength(255)
        },
        phone: {
          required
        },
        acceptedTerms: {
          required,
          terms: value => value === true
        }
      }
    };
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios
        .post("api/doctor", this.data)
        .then(() => this.$router.push({name: "signUpPerformed"}))
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loading = false);
    },
    openTerms(){
      this.activeModal = !this.activeModal;
    },
    acceptTerms(acceptedTerms){
      this.data.acceptedTerms = acceptedTerms;
    }
  }
};
</script>

<style></style>
