<template>
  <lila-input-wrapper :validation="validation">
    <lila-label :label="label" />

    <lila-control :validation="validation">
      <date-picker
        v-if="!year"
        v-bind="attrs"
        :value="attrs.modelValue"
        input-class="input"
        value-type="format"
        :lang="lang"
        format="DD/MM/YYYY"
        @update:value="$emit('update:modelValue', $event)"
      />

      <date-picker
        v-else
        input-class="input"
        value-type="format"
        :lang="lang"
        type="year"
        format="YYYY"
        class="input"
        v-bind="attrs"
        :value="attrs.modelValue"
        @update:value="$emit('update:modelValue', $event)"
      />
    </lila-control>
  </lila-input-wrapper>
</template>

<script setup>
/*
* Build using COMPOSITION API from VUE 3
* The objective of this new API is to be modular, and declarative
* everything which do something in this file, is declared in this file
* no magic or hidden behaiviour
*/
import DatePicker from "vue-datepicker-next"; // https://github.com/mengxiong10/vue-datepicker-next
import "vue-datepicker-next/index.css";
import { useAttrs } from "vue";

import useInputProps from "@composables/useInputProps.js";
import LilaControl from "@components/shared/fields/LilaControl.vue";
import LilaLabel from "@components/shared/fields/LilaLabel.vue";
import LilaInputWrapper from "@components/shared/fields/LilaInputWrapper.vue";

const emit = defineEmits(['update:modelValue']);

defineProps({
  ...useInputProps(),
  year: Boolean
});

const attrs = useAttrs(); // This bind every prop and listenner to the parent (this way no need to declare every single prop and event)

const lang = {
  days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
  pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
  placeholder: {
    date: "",
    dateRange: "Select Date Range"
  }
};
</script>
