import { reactive } from "vue";

const listeners = reactive({});

export const eventBus = {
  emit(event, payload) {
    if (listeners[event]) {
      listeners[event].forEach((callback) => callback(payload));
    }
  },
  on(event, callback) {
    if (!listeners[event]) {
      listeners[event] = [];
    }
    listeners[event].push(callback);
  },
  off(event, callback) {
    if (listeners[event]) {
      const index = listeners[event].indexOf(callback);
      if (index > -1) {
        listeners[event].splice(index, 1);
      }
    }
  }
};