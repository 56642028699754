<template>
  <lila-default-template :loading-signal-r="loadingSignalR">
    <template #mainContent>
      <div
        class="container patient-list"
        :class="loadingContent ? 'loading large' : ''"
      >
        <h1 class="title">
          Pacientes
        </h1>

        <lila-message :alert-data="alert" />

        <div
          v-show="!loadingContent"
          class="columns"
        >
          <div class="column is-narrow patient-sidebar">
            <div
              ref="conversationList"
              class="conversation-list custom-scroll"
            >
              <div class="conversation-content">
                <div class="conversation-list-header patient-list-header">
                  <div
                    :class="!$AccessControl.cannot(
                      $Session.get($userSessionName).profile,
                      'add_patient'
                    )
                      ? 'box-input-search'
                      : ''
                    "
                  >
                    <lila-button
                      v-if="!$AccessControl.cannot(
                        $Session.get($userSessionName).profile,
                        'add_patient'
                      )
                      "
                      type="route"
                      title="Adicionar"
                      class="submit-buttons"
                      path="step1PatientInformation"
                    />
                    <form @submit.prevent="filterBy('byNameOrRegisterNumber')">
                      <lila-input
                        v-model="search.byNameOrRegisterNumber"
                        type="inputSearch"
                        class="with-shadow"
                        placeholder="Pesquisar"
                        title="Insira o nome ou número de matrícula para pesquisar"
                        :readonly="loadingInfinityScroll"
                        :btn-clear-search="search.activateClearSearchBtn"
                        @custom-clear-search-input-event="
                          clearFilterBy('byNameOrRegisterNumber')
                        "
                      />
                    </form>
                  </div>

                  <div class="menu">
                    <ul>
                      <li
                        :class="filterByVisible ? 'active' : ''"
                        @click="openOptions('filterBy')"
                      >
                        Filtrar por
                      </li>
                    </ul>

                    <button
                      v-show="filterByVisible &&
                        (search.activeCid || search.activeEstadio)
                      "
                      class="clear-filter"
                      :disabled="loadingInfinityScroll"
                      @click="clearFilterBy('byCidEstadio')"
                    >
                      <lila-svgIcon :src="require('@assets/icons/delete.svg')" />
                      Limpar Filtro
                    </button>
                  </div>

                  <div
                    v-show="orderByVisible"
                    class="order-by"
                  >
                    <lila-radio
                      label=""
                      name="orderBy"
                      :options="orderByList"
                    />
                  </div>

                  <div
                    v-show="filterByVisible"
                    class="filter-by"
                  >
                    <lila-input
                      v-model="search.cidCode"
                      placeholder="Selecionar CID"
                      class="filter-by-cid"
                      :readonly="true"
                      :disabled="loadingInfinityScroll"
                      @custom-click-event="openCidModal"
                    />

                    <lila-select
                      v-model="search.byEstadio"
                      placeholder="Selecionar Estadio"
                      :options="estadioList"
                      :readonly="loadingInfinityScroll"
                      @custom-change-event="filterBy('byEstadio')"
                    />
                  </div>
                </div>

                <lila-patient-card
                  :data-list="data.patientList"
                  :selected-patient="data.selectedPatient || {}"
                  router-path="myPatientChat"
                  @custom-click-event="selectPatient"
                />

                <!-- Sentinel Element -->
                <div ref="scrollSentinel" />

                <div
                  v-show="loadingInfinityScroll"
                  class="loading small"
                />
                <div
                  v-show="pagination.lastPage"
                  class="last-page has-text-centered"
                >
                  - - -
                </div>
              </div>
            </div>
          </div>

          <div class="column patient-column">
            <!-- Fallback message when no patient is selected -->
            <div
              v-if="pageName == 'myPatients' || !data.selectedPatient"
              class="wait-patient has-text-centered"
            >
              <figure class="image">
                <img :src="require('@assets/images/doctor.png')">
              </figure>
              <p>
                Clique nos cards ao lado para interagir e para mais informações
              </p>
            </div>

            <!-- When a patient is selected, show the patient details and menu -->
            <div
              v-else
              ref="patientBox"
              class="patient-box"
            >
              <lila-patient-card-summary :patient="data.selectedPatient" />

              <div class="menu-patient-card">
                <ul>
                  <li
                    :class="$route.name == 'myPatientMedicalRecord' ? 'active' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'myPatientMedicalRecord',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Ficha
                    </router-link>
                  </li>
                  <li
                    :class="$route.name == 'myPatientHistoryRecords' ? 'active' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'myPatientHistoryRecords',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Registros
                    </router-link>
                  </li>
                  <li
                    v-show="userHasPermission(2)"
                    :class="$route.name == 'mypatientPrescriptions' ? 'active' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'mypatientPrescriptions',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Receita Médica
                    </router-link>
                  </li>
                  <li
                    :class="$route.name == 'myPatientClinicalEvolution'
                      ? 'active'
                      : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'myPatientClinicalEvolution',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Evolução Clínica
                    </router-link>
                  </li>

                  <li :class="$route.name == 'myPatientChat' ? 'active' : ''">
                    <router-link
                      :to="{
                        name: 'myPatientChat',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Conversa
                    </router-link>
                  </li>

                  <li
                    v-show="userHasPermission(8)"
                    :class="$route.name == 'myPatientBarriers' ? 'active' : ''"
                  >
                    <router-link :to="{ name: 'myPatientBarriers', params: { id: data.selectedPatient.Id } }">
                      Barreiras
                    </router-link>
                  </li>
                  <li
                    v-if="hasAccessToActionFeature()"
                    :class="$route.name == 'myPatientActions' ? 'active' : ''"
                  >
                    <router-link :to="{ name: 'myPatientActions', params: { id: data.selectedPatient.Id } }">
                      Ações
                    </router-link>
                  </li>

                  <li
                    :class="$route.name == 'myPatientChatHistory' ? 'active' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'myPatientChatHistory',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Conversas Encerradas
                    </router-link>
                  </li>

                  <li
                    :class="$route.name == 'myPatientAttachments' ? 'active' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'myPatientAttachments',
                        params: {
                          id: data.selectedPatient.Id,
                        },
                      }"
                    >
                      Anexos
                    </router-link>
                  </li>
                </ul>
              </div>

              <router-view
                :closed-ticket-id="closedTicketId"
                :new-message-for-patient="newMessageForPatient"
                @update-patient="updatePatient"
                @update-patient-card-summary="updatePatientCardSummary"
              />
            </div>
          </div>
        </div>

        <lila-cid-modal
          :modal-active="cidModalActive"
          @custom-click-event="selectCid"
        />
      </div>
    </template>
  </lila-default-template>
</template>

<script lang="ts">
import { defineComponent, ref, provide, watch } from "vue";
import DefaultTemplate from "@components/template/DefaultTemplate.vue";
import Input from "@components/shared/fields/Input.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import Radio from "@components/shared/fields/Radio.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";
import PatientCard from "@components/shared/lila/PatientCard.vue";
import PatientCardSummary from "@components/shared/lila/PatientCardSummary.vue";
import CidModal from "@components/shared/lila/CidModal.vue";
import {
  Patient,
  CIDType,
  PatientCID,
  Alert,
  Address,
  FrontendPatient
} from "@/models";
import {
  PerformanceStatus,
  performanceStatusList
} from "@/enums/PerformanceStatus";
import decodeToken from "@/js/decodeToken";
import { utilities } from "@/plugins/UtilitiesV2";
import { HubConnection } from "@microsoft/signalr";
import { AlertMessage, AlertType } from "@/enums/Index";

require("@microsoft/signalr");

export default defineComponent({
  components: {
    "lila-input": Input,
    "lila-select": Select,
    "lila-button": Button,
    "lila-radio": Radio,
    "lila-svgIcon": SvgIcon,
    "lila-patient-card": PatientCard,
    "lila-patient-card-summary": PatientCardSummary,
    "lila-message": Message,
    "lila-cid-modal": CidModal,
    "lila-default-template": DefaultTemplate
  },
  setup() {
    const alert = ref<Alert>({
      message: "",
      type: AlertType.SUCCESS, // usando como default
      show: false
    });

    provide("alert-message", alert);

    return {
      alert
    };
  },
  data() {
    return {
      userId: null as number | null,
      data: {
        selectedPatient: null as FrontendPatient | null,
        patientList: [] as FrontendPatient[]
      },
      orderByVisible: false,
      filterByVisible: false,
      orderByList: [
        { _id: 1, name: "Nome do Paciente" },
        { _id: 2, name: "Data da última mensagem" }
      ],
      performanceStatusList: require("@assets/options/performanceStatus.json")
        .options,
      estadioList: require("@assets/options/estadio.json").options,
      search: {
        byNameOrRegisterNumber: "",
        byCid: "",
        byEstadio: "",
        cidCode: "", 
        activeNameOrRegisterNumber: false,
        activeCid: false,
        activeEstadio: false,
        activateClearSearchBtn: false
      },
      loadingSignalR: false,
      ticketSignalR: {
        active: false,
        connection: null as any,
        timeout: false,
        retryCount: 0
      },
      signalRTimeout: 5000,
      signalRMaxRetry: 3,
      newMessageForPatient: {} as any,
      closedTicketId: null as number | null,
      cidModalActive: false,
      pageName: this.$route.name,
      loading: false,
      loadingContent: false,
      loadingInfinityScroll: false,
      firstLoading: true,
      patientColumnHeight: 0,
      patientColumnHeightInterval: null as any,
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10,
        lastPage: false
      }
    };
  },

  watch: {
    $route(to) {
      this.pageName = to.name;
      if (this.pageName === "myPatients") this.data.selectedPatient = null;
    }
  },

  created() {
    const session = this.$Session.get(this.$userSessionName);
    this.userId =
      session.profile === "doctor"
        ? session.doctor.Id
        : session.professional.Id;

    if (this.$route.query.customMessageType) {
      const data = {
        customMessage: {
          type: this.$route.query.customMessageType,
          message: this.$route.query.customMessage
        }
      };
      utilities.showResultMessage(data, this.alert);
    } else {
      utilities.showResultMessage(this.$route.query, this.alert);
    }

    this.getPatientList();
    this.startTicketSignalR();
  },

  mounted() {
    this.controlSidebarHeight();

    // Configura o IntersectionObserver ao montar o componente
    this.setupIntersectionObserver();
  },

  beforeUnmount() {
    clearInterval(this.patientColumnHeightInterval);
    this.closeSignalRConnections();
  },

  methods: {
    /**
     * Configura o IntersectionObserver para detectar quando o usuário rola
     * próximo ao final da lista de pacientes e aciona o carregamento de mais pacientes.
     */
    setupIntersectionObserver() {
      // Define as opções para o IntersectionObserver sem usar IntersectionObserverInit diretamente
      const options = {
        root: this.$refs.conversationList as HTMLElement | null, // Certifica-se de que é um elemento HTML ou null
        rootMargin: "0px",
        threshold: 0.1 // Dispara quando 10% do sentinel estiver visível
      };

      // Cria o observer usando o método de callback 'handleIntersection'
      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );

      const sentinel = this.$refs.scrollSentinel as HTMLElement;

      // Verifica se o sentinel existe antes de observar
      if (sentinel) {
        observer.observe(sentinel);
      }
    },

    /**
     * Callback do IntersectionObserver para carregar mais pacientes
     * quando o sentinel estiver visível (indicando que o usuário chegou perto do final da lista).
     */
    handleIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.loadingInfinityScroll) {
          // Carrega mais pacientes ao observar o sentinel visível
          this.getPatientList(this.pagination.current + 1);
        }
      });
    },

    mapToFrontendPatient(item: any): FrontendPatient {
      return {
        Id: item.Id,
        Name: item.Name,
        SocialName: "",
        profilePicture: item.Photo
          ? item.Photo
          : utilities.getDefaultProfilePicture(item.Gender),
        displayCid: utilities.getPatientCid(item) || "",
        Gender: item.Gender,
        BirthDate: item.BirthDate,
        Diagnostic: item.Diagnostic,
        Status: item.Status,
        CIDs: item.CIDs,
        painLevel: "",
        doctor: item.Doctor?.Name || "",
        serviceLocation: item.AttendancePlace
          ? item.AttendancePlace.Street
          : "",
        imgLoaded: false,
        ticketId: item.OpenTicket ? item.OpenTicket.Id : null,
        doctorPriority: item.OpenTicket ? item.OpenTicket.DoctorPriority : "",
        Race: item.Race,
        Education: item.Education,
        Employment: item.Employment,
        MaritalStatus: item.MaritalStatus,
        JourneyStage: item.JourneyStage,
        SymptomStartPeriod: item.SymptomStartPeriod,
        selectedRace: "",
        selectedMaritalStatus: "",
        selectedEmployment: "",
        selectedEducation: "",
        selectedSymptomStartPeriod: "",
        selectedJourneyStage: "",
        CNS: "",
        Observations: ""
      };
    },

    async getPatientList(page = 1, searchType = "", localLoading = false) {
      // Verifica se já está carregando ou se atingiu a última página
      if (this.isAlreadyLoading() || this.pagination.lastPage) {
        return;
      }

      // Monta os parâmetros da requisição
      const apiEndpoint = this.buildApiEndpoint(page, searchType);

      // Reseta a visibilidade do alerta se não for o primeiro carregamento
      if (!this.firstLoading) {
        //this.alert.show = false;
      }

      // Define o tipo de carregamento (infinito ou completo)
      this.prepareForLoading(searchType, localLoading, page);

      try {
        const response = await this.fetchPatientData(apiEndpoint);

        // Marca o fim do primeiro carregamento
        this.firstLoading = false;

        // Atualiza a paginação com os dados da resposta
        this.updatePagination(response.data.Pagination);

        // Mapeia os pacientes da resposta para o frontend
        this.updatePatientList(response.data.Data);

        // Verifica se há um paciente selecionado na rota
        await this.handleSelectedPatientFromRoute();

        // Exibe uma mensagem de alerta se não houver pacientes
        this.handleEmptyPatientList();
      } catch (error) {
        this.handleRequestError(error);
      } finally {
        // Finaliza os estados de carregamento
        this.resetLoadingStates();
      }
    },

    /**
     * Verifica se já está carregando a lista ou se o scroll infinito está ativo.
     */
    isAlreadyLoading() {
      return this.loadingContent || this.loadingInfinityScroll;
    },

    /**
     * Constrói o endpoint da API com base nos parâmetros de paginação e busca.
     */
    buildApiEndpoint(page: number, searchType: string): string {
      const paginationParams = `?page=${page}&limit=${this.pagination.perPage}`;
      const searchParams = this.createParameters();

      return searchParams
        ? `/api/patient/search${paginationParams}${searchParams}`
        : `/api/patient${paginationParams}`;
    },

    /**
     * Define o estado de carregamento, diferenciando entre carregamento completo e infinito.
     */
    prepareForLoading(searchType: string, localLoading: boolean, page: number) {
      if (searchType !== "" || localLoading || page > 1) {
        if (searchType !== "") {
          this.data.patientList = [];
        }
        this.loadingInfinityScroll = true;
        this.scrollPatientListToBottom();
      } else {
        this.loadingContent = true;
      }
    },

    /**
     * Faz a requisição à API para buscar os dados dos pacientes.
     */
    async fetchPatientData(apiEndpoint: string) {
      return await this.$axios.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${
            this.$Session.get(this.$userSessionName).token.Value
          }`
        }
      });
    },

    /**
     * Atualiza a paginação com os dados recebidos da API.
     */
    updatePagination(paginationData: any) {
      if (paginationData.TotalPage === paginationData.CurrentPage) {
        this.pagination.lastPage = true;
      }
      this.pagination.current = paginationData.CurrentPage;
      this.pagination.totalPages = paginationData.TotalPage;
    },

    /**
     * Mapeia os dados da resposta para o formato usado no frontend e adiciona à lista de pacientes.
     */
    updatePatientList(patientData: any[]) {
      this.data.patientList = [
        ...(this.data.patientList || []),
        ...patientData
          .filter((item) => item != null)
          .map(this.mapToFrontendPatient)
      ];
    },

    /**
     * Verifica se há um paciente selecionado via rota e, se necessário, faz a busca por ele.
     */
    async handleSelectedPatientFromRoute() {
      if (this.$route.params.id) {
        const patientId = Array.isArray(this.$route.params.id)
          ? parseInt(this.$route.params.id[0], 10)
          : parseInt(this.$route.params.id, 10);

        if (!isNaN(patientId)) {
          await this.getSelectedPatient(patientId); // Busca o paciente selecionado
        } else {
          console.error("Invalid patient ID:", this.$route.params.id);
          this.$router.push({ name: "myPatients" });
        }

        if (!this.data.selectedPatient) {
          this.$router.push({ name: "myPatients" });
        }
      }
    },

    /**
     * Exibe uma mensagem de alerta caso a lista de pacientes esteja vazia.
     */
    handleEmptyPatientList() {
      if (this.data.patientList.length === 0) {
        this.alert.type = AlertType.WARNING;
        this.alert.message = AlertMessage.EMPTY_LIST;
        utilities.controlAlert(null, this.alert);
      }
    },

    /**
     * Lida com erros que ocorrem durante a requisição, exibindo uma mensagem apropriada.
     */
    handleRequestError(error: any) {
      if (utilities.isAxiosError(error) && error.response) {
        utilities.verifyToken(error.response.status);
        this.alert.type = AlertType.DANGER;
        this.alert.message = AlertMessage.ERROR;
        utilities.controlAlert(error.response.data, this.alert);
      } else {
        console.error("Unexpected error:", error);
        this.alert.type = AlertType.DANGER;
        this.alert.message = AlertMessage.ERROR;
        utilities.controlAlert(null, this.alert);
      }
    },

    /**
     * Reseta os estados de carregamento após a finalização da requisição.
     */
    resetLoadingStates() {
      this.loadingContent = false;
      this.loadingInfinityScroll = false;
    },

    async getPatientPain(patientId: number) {
      try {
        const response = await this.$axios.get(
          `/api/clinicalevolution/pain/patient/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$Session.get(this.$userSessionName).token.Value
              }`
            }
          }
        );

        if (response.data && this.data.selectedPatient) {
          // Check if selectedPatient exists
          this.data.selectedPatient.painLevel = `EVA ${response.data.Evolutions[0].Intensity}`;
        }
      } catch (error) {
        console.error("Error fetching patient pain:", error);
        if (utilities.isAxiosError(error) && error.response) {
          utilities.verifyToken(error.response.status);
        } else {
          console.error("Unexpected error:", error);
        }
      } finally {
        this.loadingContent = false;
      }
    },
    scrollPatientListToBottom() {
      setTimeout(() => {
        const conversationList = this.$refs
          .conversationList as HTMLElement | null;
        if (conversationList) {
          conversationList.scrollTo(0, conversationList.scrollHeight);
        }
      }, 50);
    },
    openOptions(option: string) {
      if (option === "orderBy") {
        this.orderByVisible = !this.orderByVisible;
        this.filterByVisible = false;
      } else if (option === "filterBy") {
        this.filterByVisible = !this.filterByVisible;
        this.orderByVisible = false;
      }
    },
    selectPatient(patient: FrontendPatient) {
      this.data.selectedPatient = { ...patient };
      this.getPatientPain(patient.Id);
    },

    async getPatientById(patientId: number) {
      try {
        const response = await this.$axios.get(`/api/patient/${patientId}`, {
          headers: {
            Authorization: `Bearer ${
              this.$Session.get(this.$userSessionName).token.Value
            }`
          }
        });

        const patient = response.data;

        if (patient) {
          this.selectPatient(this.mapToFrontendPatient(patient)); // Use the mapping utility here
        } else {
          console.warn("Patient not found, resetting to patient list");
          this.$router.push({ name: "myPatients" });
        }
      } catch (error) {
        console.error("Error fetching patient by ID:", error);
        this.$router.push({ name: "myPatients" });
      }
    },
    // Adjusted method to select a patient if they exist in the list, otherwise fetch from the server
    async getSelectedPatient(patientId: number) {
      const patient = this.data.patientList.find(
        (item) => item.Id === patientId
      );

      if (patient) {
        // Patient is already in the list, select the patient
        this.selectPatient(patient);
      } else {
        // If not found, asynchronously fetch the patient from the server
        try {
          await this.getPatientById(patientId); // Await the async call
        } catch (error) {
          console.error("Error fetching patient by ID:", error);
        }
      }
    },

    updatePatient(patient: FrontendPatient) {
      // Iterate through the patient list to find the one we need to update
      for (let index = 0; index < this.data.patientList.length; index++) {
        if (this.data.patientList[index].Id === this.data.selectedPatient?.Id) {
          // Update relevant fields in the patient list entry
          this.data.patientList[index].Name = patient.Name;
          this.data.patientList[index].displayCid =
            utilities.getSelectedPatientCid(patient);
          this.data.patientList[index].Gender = patient.Gender;
          this.data.patientList[index].BirthDate = patient.BirthDate
            ? utilities.formatDate(
                patient.BirthDate,
                "DD/MM/YYYY",
                "MM/DD/YYYY"
              )
            : "";
          this.data.patientList[index].Diagnostic = patient.Diagnostic;

          // Additional fields that might need to be updated based on your logic
          break;
        }
      }
    },
    
    updatePatientCardSummary(patient: FrontendPatient) {
      // Ensure the selected patient exists
      if (!this.data.selectedPatient) return;

      // Update the selectedPatient with the new patient data
      this.data.selectedPatient.Name = patient.Name;
      this.data.selectedPatient.displayCid =
        utilities.getSelectedPatientCid(patient); // Update displayCid with the processed CID
      this.data.selectedPatient.Gender = patient.Gender;
      this.data.selectedPatient.BirthDate = patient.BirthDate
        ? utilities.formatDate(patient.BirthDate, "DD/MM/YYYY", "MM/DD/YYYY")
        : "";
      this.data.selectedPatient.Diagnostic = patient.Diagnostic;

      if (patient.painLevel) {
        this.data.selectedPatient.painLevel = patient.painLevel;
      }

    },
    openCidModal() {
      this.cidModalActive = !this.cidModalActive;
    },
    selectCid(cid: any) {
      this.search.byCid = cid.CIDTypeId;
      this.search.cidCode = cid.CIDCode;

      this.filterBy("byCid");
    },

    createParameters() {
      let params = "";

      // Construct params string based on active search filters
      if (this.search.activeNameOrRegisterNumber)
        params += `&queryParameter=${this.search.byNameOrRegisterNumber}`;
      if (this.search.activeCid) params += `&cidId=${this.search.byCid}`;
      if (this.search.activeEstadio)
        params += `&estadio=${this.search.byEstadio}`;

      return params;
    },

    filterBy(filterBy: string) {
      // Logic to apply different filters based on the filterBy parameter
      switch (filterBy) {
        case "byNameOrRegisterNumber":
          this.search.activeNameOrRegisterNumber = true;
          this.search.activateClearSearchBtn = true;
          break;
        case "byCid":
          this.search.activeCid = true;
          break;
        case "byEstadio":
          this.search.activeEstadio = !!this.search.byEstadio;
          break;
      }

      // Clear pagination and fetch the filtered patient list from page 1
      this.clearPagination();
      this.getPatientList(1, filterBy);
    },

    clearFilterBy(filterBy: string) {
      // Clear filters based on the type of filter being reset
      switch (filterBy) {
        case "byNameOrRegisterNumber":
          this.clearSearchByNameFields();
          break;
        case "byCidEstadio":
          this.clearFilterByFields();
          break;
      }

      // Fetch patient list without any active filters
      this.getPatientList(1, "", true);
    },

    clearSearchByNameFields() {
      // Reset the search fields for filtering by name or register number
      this.search.byNameOrRegisterNumber = "";
      this.search.activeNameOrRegisterNumber = false;
      this.search.activateClearSearchBtn = false;

      // Reset pagination for a fresh query
      this.clearPagination();
    },

    clearFilterByFields() {
      // Clear CID and Estadio filters
      this.search.byCid = "";
      this.search.cidCode = "";
      this.search.byEstadio = "";
      this.search.activeCid = false;
      this.search.activeEstadio = false;

      // Reset pagination for a fresh query
      this.clearPagination();
    },

    clearPagination() {
      // Reset pagination values to default for a fresh query
      this.pagination.current = 0;
      this.pagination.totalPages = 0;
      this.pagination.lastPage = false;
    },

    checkEndOfPage() {
      // Delay the execution by 200 milliseconds (you can adjust the value as needed)
      setTimeout(() => {
        const conversationList = this.$refs.conversationList as HTMLElement;

        if (conversationList) {
          const { scrollTop, clientHeight, scrollHeight } = conversationList;

          // Check if the user has scrolled near the end of the list
          if (scrollTop + clientHeight + 250 >= scrollHeight) {
            this.getPatientList(this.pagination.current + 1);
          }
        }
      }, 1000); // Adjust the delay as needed
    },
    controlSidebarHeight() {
      this.patientColumnHeightInterval = setInterval(() => {
        const patientBox = this.$refs.patientBox as HTMLElement | null;
        const conversationList = this.$refs
          .conversationList as HTMLElement | null;

        // Verifica se os elementos existem e se a altura mudou
        if (
          patientBox &&
          conversationList &&
          this.patientColumnHeight !== patientBox.offsetHeight
        ) {
          this.patientColumnHeight = patientBox.offsetHeight;
          conversationList.style.height = `${this.patientColumnHeight - 50}px`;
        }
      }, 500);
    },
    userHasPermission(feature: number) {
      const sessionToken = this.$Session.get(this.$userSessionName)?.token
        ?.Value;

      // Use the decodeToken method from Utilities class
      const decodedToken = utilities.decodeToken(sessionToken);

      // Ensure decodedToken is not null and has the expected FeaturesId property
      return decodedToken && decodedToken.FeaturesId?.includes(feature);
    },

    hasAccessToActionFeature() {
      return utilities.hasAccessToFeature(7);
    },

    startTicketSignalR() {
      try {
        // Check if the connection already exists to avoid multiple connections
        if (this.ticketSignalR.connection != null) return true;

        // Start the SignalR connection using your SignalR hub (messageprofessionalhub)
        this.ticketSignalR.connection = this.$SignalRConnector.startSignalR(
          "messageprofessionalhub",
          (connection: HubConnection) => {
            // Add the explicit type for the connection
            // Once connected, start listening to events
            this.startTicketSignalRListeners(connection);
          },
          this.signalRTimeout, // Timeout configuration for the connection
          String(this.userId) // Pass the userId as an identifier
        );
      } catch (error) {
        console.log("Erro na comunicação com o SignalR:", error);

        // Retry the connection a few times if it fails
        if (this.ticketSignalR.retryCount < this.signalRMaxRetry) {
          ++this.ticketSignalR.retryCount;

          // Retry the whole connection process instead of calling startTicketSignalRListeners directly
          this.startTicketSignalR();
        }
      }
    },

    async startTicketSignalRListeners(connection: HubConnection) {
      try {
        // Register listener for receiving messages for patients
        connection.on("PatientMessage", (res) => {
          this.loadingSignalR = true;

          // Handle incoming message, update the state accordingly
          this.newMessageForPatient = {
            patientId: res.fromId,
            ticketId: res.ticketId
          };
        });

        // Register listener for chat closed event
        connection.on("ChatClosed", (res) => {
          this.loadingSignalR = true;

          // Handle chat closed event, update the state accordingly
          this.closedTicketId = res.id;
          this.loadingSignalR = false;
        });
      } catch (error) {
        console.error("Erro ao iniciar os listeners do SignalR:", error);
      }
    },

    closeSignalRConnections() {
      // Check if the timeout is a valid ID and not a boolean
      if (typeof this.ticketSignalR.timeout === "number") {
        clearTimeout(this.ticketSignalR.timeout);
      }

      // Stop the SignalR connection if it exists
      if (this.ticketSignalR.connection) {
        this.$SignalRConnector.stopSignalR(this.ticketSignalR.connection);
      }

      // Set the active state to false
      this.ticketSignalR.active = false;
    }
  }
});
</script>

<style lang="scss">
@import "~@assets/css/components/patientTickets.css";
@import "~@assets/css/views/patients.css";
@import "~@assets/css/views/patientTickets.css";

.patient-list .columns {
  display: flex;
}

.patient-list .patient-sidebar {
  flex: 1;
  max-width: 400px;
}

.patient-list .patient-column {
  flex: 2;
  min-width: 300px;
}
</style>
