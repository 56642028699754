// /enums/AlertType.ts

/**
 * Enum AlertType
 * 
 * Este enum é usado para definir os tipos de alerta permitidos em nossa aplicação, como "danger" (erro), 
 * "success" (sucesso), e "warning" (aviso). A principal vantagem de utilizar um enum em TypeScript é garantir 
 * a segurança de tipos, assegurando que apenas os valores válidos possam ser usados ao definir o tipo de alerta.
 * 
 * Mantemos os valores como strings ("danger", "success", "warning") para garantir a compatibilidade com 
 * componentes e códigos que ainda não utilizam TypeScript, como o componente Message.vue. Dessa forma, 
 * a transição entre o código em TypeScript e o código JavaScript tradicional é suave, evitando quebras de compatibilidade.
 */
export enum AlertType {
    DANGER = 'danger',
    SUCCESS = 'success',
    WARNING = 'warning'
  }
  