import app from "./main.js";
import {createRouter, createWebHistory} from "vue-router";

import Login from "./views/login/Login.vue";
import AppTerms from "./views/AppTerms.vue";
import SignUp from "./views/login/SignUp.vue";
import SignUpPerformed from "./views/login/SignUpPerformed.vue";
import CreatePassword from "./views/login/CreatePassword.vue";
import ResetPassword from "./views/login/ResetPassword.vue";
import ResetAppPassword from "./views/login/ResetAppPassword.vue";
import ForgotPassword from "./views/login/ForgotPassword.vue";
import CompleteYourRegistration from "./views/login/CompleteYourRegistration.vue";

//Completar Cadastro
import BasicInformation from "./views/complementaryRegistration/BasicInformation.vue";
import ComplementaryInformation from "./views/complementaryRegistration/ComplementaryInformation.vue";

import SubmenuPerfil from "./views/complementaryRegistration/SubmenuPerfil.vue";
import Perfil from "./views/complementaryRegistration/Perfil.vue";

import WhoWeAre from "./views/WhoWeAre.vue";
import Announcements from "./views/Announcements.vue";
import ChangePassword from "./views/login/ChangePassword.vue";

//Chamados Pendentes
import PatientTicketList from "./views/patientTicket/PatientTicketList.vue";

//Meus Pacientes
import PatientList from "./views/patients/PatientList.vue";
import MedicalRecord from "./views/patients/submenu/MedicalRecord.vue";
import HistoryRecords from "./views/patients/submenu/HistoryRecords.vue";
import Prescriptions from "./views/patients/submenu/Prescriptions.vue";
import ClinicalEvolution from "./views/patients/submenu/ClinicalEvolution.vue";
import Chat from "./views/patients/submenu/Chat.vue";
import ChatHistory from "./views/patients/submenu/ChatHistory.vue";
import AttachedDocuments from "./views/patients/submenu/AttachedDocuments.vue";
import Actions from "./views/patients/submenu/Actions.vue";
import Barriers from "./views/patients/submenu/Barriers.vue";
import LilaCalendar from "./views/LilaCalendar.vue";

import Step1PatientInformation from "./views/patients/register/Step1PatientInformation.vue";
import Step2PatientInformation from "./views/patients/register/Step2PatientInformation.vue";
import Step3PatientInformation from "./views/patients/register/Step3PatientInformation.vue";
import Step4PatientInformation from "./views/patients/register/Step4PatientInformation.vue";

import AllActions from "./views/AllActions.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {path: "/", name: "login", component: Login},
    {path: "/termos_uso", name: "terms", component: AppTerms},
    {path: "/home", name: "home"},
    {path: "/cadastro", name: "signUp", component: SignUp},
    {path: "/cadastro_realizado", name: "signUpPerformed", component: SignUpPerformed},
    {path: "/criar_senha/:token?", name: "createPassword", component: CreatePassword},
    {path: "/resetar_senha/:token?", name: "resetPassword", component: ResetPassword},
    {path: "/resetar_senha_app/:token?", name: "resetAppPassword", component: ResetAppPassword},
    {path: "/esqueci_senha", name: "forgotPassword", component: ForgotPassword},
    {path: "/complete_seu_cadastro", name: "completeYourRegistration", component: CompleteYourRegistration},
    {path: "/cadastro_basico", name: "basicInformation", component: BasicInformation},
    {path: "/cadastro_complementar", name: "complementaryInformation", component: ComplementaryInformation},

    {path: "/chamados_pendentes", name: "patientTickets", title: "Chamados", component: PatientTicketList,
      children: [
        {path: "ficha/:id", name: "patientMedicalRecord", component: MedicalRecord},
        {path: "registros/:id", name: "patientHistoryRecord", component: HistoryRecords},
        {path: "prescriptions/:id", name: "patientPrescriptions", component: Prescriptions},
        {path: "evolucao_clinica/:id", name: "patientClinicalEvolution", component: ClinicalEvolution},
        {path: "conversa/:id", name: "patientChat", component: Chat},
        {path: "barriers/:id", name: "patientBarriers", component: Barriers},
        {path: "actions/:id", name: "patientActions", component: Actions},
        {path: "conversas_encerradas/:id", name: "patientClosedMessages", component: ChatHistory},
        {path: "anexos/:id", name: "patientAttachments", component: AttachedDocuments}
      ]
    },
    {path: "/pacientes", exact: true, name: "myPatients", title: "Pacientes", component: PatientList,
      children: [
        {path: "ficha/:id", name: "myPatientMedicalRecord", component: MedicalRecord},
        {path: "registros/:id", name: "myPatientHistoryRecords", component: HistoryRecords},
        {path: "prescriptions/:id", name: "mypatientPrescriptions", component: Prescriptions},
        {path: "evolucao_clinica/:id", name: "myPatientClinicalEvolution", component: ClinicalEvolution},
        {path: "conversa/:id", name: "myPatientChat", component: Chat},
        {path: "barriers/:id", name: "myPatientBarriers", component: Barriers},
        {path: "actions/:id", name: "myPatientActions", component: Actions},
        {path: "conversas_encerradas/:id", name: "myPatientChatHistory", component: ChatHistory},
        {path: "anexos/:id", name: "myPatientAttachments", component: AttachedDocuments}
      ]
    },
    {path: "/pacientes/cadastrar/passo1", name: "step1PatientInformation", component: Step1PatientInformation, meta: {access_control: "add_patient"}},
    {path: "/pacientes/cadastrar/passo2/:id", name: "step2PatientInformation", component: Step2PatientInformation, meta: {access_control: "add_patient"}},
    {path: "/pacientes/cadastrar/passo3/:id", name: "step3PatientInformation", component: Step3PatientInformation, meta: {access_control: "add_patient"}},
    {path: "/pacientes/cadastrar/passo4/:id", name: "step4PatientInformation", component: Step4PatientInformation, meta: {access_control: "add_patient"}},

    {path: "/acoes", name: "acoes", title: "Ações", component: AllActions},
    {path: "/comunicados", name: "communicated", title: "Comunicados", component: Announcements, meta: {access_control: "announcements_page"}},
    {path: "/agenda", name: "calendar", title: "Agenda", component: LilaCalendar},
    {path: "/", name: "userName" , title: "", action: "userName"},
    {path: "/", name: "dropDownMenu" , title: "", action: "submenu", component: SubmenuPerfil,
      children: [
        {path: "dados_cadastrais", name: "registrationData", title: "Dados Cadastrais", component: Perfil},
        {path: "alterar_senha", name: "changePassword", title: "Alterar Senha", component: ChangePassword},
        {path: "#", name: "tutorial", title: "Tutorial"},
        {path: "termo_uso", name: "termsOfUse", title: "Termo de Uso", component: BasicInformation},
        {path: "quem_somos", name: "whoWeAre", title: "Quem Somos", component: WhoWeAre},
        {path: "logout", name: "logout", action: "logout", title: "Logout", component: null}
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {

  if(!app.config.globalProperties.$notRequiredAuth.includes(to.name) && app.config.globalProperties.$Session.get(app.config.globalProperties.$userSessionName) == false){
    // Usuario não esta logado e tenta acessar alguma pagina interna
    next({name: "login"});
  }else if(app.config.globalProperties.$notRequiredAuth.includes(to.name) && app.config.globalProperties.$Session.get(app.config.globalProperties.$userSessionName) != false){
    // Usuario esta logado e tenta acessar a tela de login
    next({name: "patientTickets"});
  }else{
    // Usuario esta logado e tenta acessar qualquer pagina interna

    // Verificando se o usuario possui acesso a tela
    if(typeof to.meta.access_control != "undefined"){
      if(app.config.globalProperties.$AccessControl.cannot(app.config.globalProperties.$Session.get(app.config.globalProperties.$userSessionName).profile, to.meta.access_control)) {
        next({name: "myPatients"});
        return;
      }
    }

    next();
  }
});



export default router;
