const props = {
  label: {
    default: null,
    type: String
  },
  validation: {
    required: false,
    type: Object,
    default: () => {
      return {
        $error: false
      };
    }
  }
};
export default function (returnPropsSelected = []) {
  if (!returnPropsSelected.length) return props;

  const selected = {};

  for (let index = 0; index < returnPropsSelected.length; index++) {
    const item = returnPropsSelected[index];
    if (!props[item]) continue;

    selected[item] = props[item];
  }

  return selected;
}
