<template>
  <lila-default-template>
    <template #mainContent>
      <div class="container">
        <h1 class="title">
          Suas ações pendentes
        </h1>

        <!-- Exibir alerta se houver uma mensagem -->
        <div
          v-if="alert.message"
          :class="`alert ${alert.type}`"
        >
          {{ alert.message }}
        </div>

        <!-- Exibir indicador de carregamento -->
        <div v-if="isLoading">
          Carregando...
        </div>

        <!-- Exibir ações se não estiver carregando e não houver erros -->
        <div
          v-else
          class="patients-with-actions"
        >
          <div
            v-for="patientWithActions in patientsWithActions"
            :key="patientWithActions.patient.Id"
            class="patient-action-row"
          >
            <!-- Usar patientWithActions.patient como FrontendPatient -->
            <div class="patient-card-column">
              <PatientCardNOTLIST
                :patient="patientWithActions.patient"
                :router-path="'myPatientMedicalRecord'"
                class="action-patient-card"
              />
            </div>

            <!-- Coluna Direita: Lista de Ações -->
            <div class="actions-list-column">
              <router-link
                v-for="action in patientWithActions.actions"
                v-show="!action.hidden"
                :key="action.Id"
                :to="{ name: 'patientActions', params: { id: patientWithActions.patient.Id } }"
                class="action-link"
              >
                <div class="action-card">
                  <div class="card-content">
                    <h3>{{ action.title }}</h3>
                    <div class="card-description">
                      <div>{{ action.description }}</div>
                      <!-- Setinha Indicadora -->
                      <span class="action-arrow">›</span>
                    </div>
                    <div
                      class="due-date"
                      :class="getDueDateClass(action.DueDate)"
                    >
                      Prazo: {{ formatDate(action.DueDate) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </lila-default-template>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, getCurrentInstance } from 'vue';
import LilaDefaultTemplate from '@components/template/DefaultTemplate.vue';
import { FrontendAction, Action, Patient, FrontendPatient } from '@/models/index';
import PatientCardNOTLIST from '@components/shared/lila/PatientCardNOTLIST.vue';
import { utilities } from '@/plugins/UtilitiesV2';

const actionsList = ref<FrontendAction[]>([]);

const isLoading = ref(false);
const alert = ref({
  type: null as string | null,
  message: '',
});

const instance = getCurrentInstance();


if (!instance) {
  throw new Error("getCurrentInstance() returned null. Ensure this is called within setup().");
}

const axios = instance.appContext.config.globalProperties.$axios;
const $Session = instance.appContext.config.globalProperties.$Session;
const $userSessionName = instance.appContext.config.globalProperties.$userSessionName;

const AlertType = {
  DANGER: 'danger',
  // Adicione outros tipos se necessário
};

const AlertMessage = {
  ERROR: 'Ocorreu um erro ao carregar as ações.',
  // Adicione outras mensagens se necessário
};

// Função para parsear a data no fuso horário local
const parseDateLocal = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // Meses em JavaScript são 0-based
};

// Método para formatar a data para exibição
const formatDate = (date: string | null): string => {
  if (!date) return 'Sem prazo';
  const parsedDate = parseDateLocal(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return parsedDate.toLocaleDateString(undefined, options);
};

// Método para determinar a classe com base na data de prazo
const getDueDateClass = (dueDate: string | null): string => {
  if (!dueDate) return '';
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Definir 'today' para o início do dia

  const due = parseDateLocal(dueDate);

  if (due < today) {
    return 'overdue';
  }

  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(today.getDate() + 7);
  oneWeekFromNow.setHours(0, 0, 0, 0);

  if (due <= oneWeekFromNow) {
    return 'due-soon';
  }

  return '';
};
// Função para criar um FrontendPatient a partir de um Patient
function createFrontendPatient(patient: Patient): FrontendPatient {
  const frontendPatient: FrontendPatient = {
    ...patient,
    painLevel: '', // Atribua valores padrão ou calcule conforme necessário
    displayCid: utilities.getSelectedPatientCid(patient),
    profilePicture: patient.Photo || null,
    imgLoaded: false,
    serviceLocation: '', // Atribua conforme necessário
    doctor: '', // Atribua conforme necessário
    ticketId: null,
    doctorPriority: null,
    selectedRace: null,
    selectedMaritalStatus: null,
    selectedEmployment: null,
    selectedEducation: null,
    selectedJourneyStage: null,
    selectedSymptomStartPeriod: null,
    frontendActions: [],
  };
  return frontendPatient;
}

// Modificar o método loadActionsFromAPI
async function loadActionsFromAPI(): Promise<void> {
  try {
    isLoading.value = true;

    // Obter o token de autenticação
    const token = $Session.get($userSessionName).token.Value;

    const response = await axios.get('/api/action/pending/professional', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const actionsFromAPI = response.data;

    actionsList.value = actionsFromAPI.map((action: Action) => {
      const patient = action.Patient;


      // Criar um FrontendPatient
      const frontendPatient = createFrontendPatient(patient);

      return {
        ...action,
        name: action.ActionType?.Name ?? 'Ação sem nome',
        title: action.ActionType?.Name ?? 'Sem título',
        description: action.ActionOutcomeObservations ?? 'Sem descrição',
        hidden: false,
        categoryClass: 'default-category',
        frontendPatient: frontendPatient,
      } as FrontendAction;
    });
  } catch (error) {
    console.error('Erro ao carregar ações da API:', error);
    alert.value.type = AlertType.DANGER;
    alert.value.message = AlertMessage.ERROR;
  } finally {
    isLoading.value = false;
  }
}

const patientsWithActions = computed((): Array<{ patient: FrontendPatient; actions: FrontendAction[] }> => {
  const groups: Record<number, FrontendAction[]> = {};

  actionsList.value.forEach((action) => {
    const patientId = action.frontendPatient.Id;
    if (!groups[patientId]) {
      groups[patientId] = [];
    }
    groups[patientId].push(action);
  });

  return Object.keys(groups)
    .map((patientId) => {
      const actions = groups[Number(patientId)];
      const patient = actions[0].frontendPatient; // Todas as ações para este paciente têm o mesmo frontendPatient

      // Ordenar as ações:
      // 1. Ações com DueDate (ordenadas por DueDate ascendente)
      // 2. Ações sem DueDate
      const sortedActions = actions.sort((a, b) => {
        const aDue = a.DueDate ? parseDateLocal(a.DueDate).getTime() : Infinity;
        const bDue = b.DueDate ? parseDateLocal(b.DueDate).getTime() : Infinity;
        return aDue - bDue;
      });

      return {
        patient: patient,
        actions: sortedActions,
      };
    });
});


onMounted(() => {
  loadActionsFromAPI(); // Carrega as ações da API
});
</script>




<style scoped>
/* Container Principal */
.container {
  padding: 10px;
  /* Mantido em 10px conforme solicitado */
}

/* Título */
.title {
  text-align: left;
  /* Alinha o título à esquerda */
  margin-bottom: 20px;
  /* Espaçamento reduzido para um visual mais compacto */
  font-size: 1.5em;
  /* Tamanho de fonte ajustado */
  color: #4f3f82;
}

/* Layout das Ações por Paciente */
.patients-with-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Espaçamento reduzido entre cada paciente */
}

/* Linha de Ações para Cada Paciente */
.patient-action-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* Espaçamento reduzido entre as colunas */
  margin-bottom: 20px;
  /* Espaçamento entre cada linha de paciente */
}

/* Coluna do PatientCard (Mantida a largura original) */
.patient-card-column {
  flex: 1;
  max-width: 300px;
  /* Mantém a largura original de 300px */
}

/* Coluna da Lista de Ações */
.actions-list-column {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Espaçamento reduzido entre as ações */
}

/* Estilização do router-link para ocupar todo o cartão de ação */
.action-link {
  text-decoration: none;
  /* Remove sublinhado */
  color: inherit;
  /* Herda a cor do texto */
}

/* Cartão de Ação */
.action-card {
  background-color: #ffffff;
  border-radius: 6px;
  /* Bordas ligeiramente arredondadas */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  /* Sombra mais sutil */
  padding: 15px;
  /* Padding reduzido para um visual mais compacto */
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  /* Indica que é clicável */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Efeito Hover no Cartão */
.action-card:hover {
  transform: translateY(-3px);
  /* Efeito de elevação mais sutil */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  /* Sombra ajustada para hover */
}

/* Título da Ação */
.action-card h3 {
  margin-bottom: 8px;
  /* Espaçamento reduzido */
  color: #4f3f82;
  font-size: 1.1em;
  /* Tamanho da fonte ajustado */
}

/* Descrição da Ação */
.card-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Texto da Descrição */
.card-description div {
  flex: 1;
  font-size: 0.9em;
  /* Tamanho da fonte reduzido */
}

/* Setinha Indicadora */
.action-arrow {
  margin-left: 10px;
  /* Espaçamento entre a descrição e a seta */
  color: #4f3f82;
  /* Cor da seta */
  font-size: 3em;
  /* Tamanho da seta */
  transition: transform 0.2s, color 0.2s;
}

/* Efeito Hover na Seta */
.action-card:hover .action-arrow {
  transform: translateX(5px);
  /* Pequeno movimento ao passar o mouse */
  color: #3b2a6e;
  /* Muda a cor da seta no hover */
}

/* Data de Prazo */
.due-date {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  cursor: default;
  /* Indica que não é clicável */
  margin-top: 8px;
  /* Espaçamento acima da data */
  border: 1px solid transparent;
  /* Borda padrão transparente */
}

/* Ações Atrasadas */
.due-date.overdue {
  background-color: #ffe6e6;
  /* Fundo rosa claro */
  color: #ff4d4d;
  /* Texto vermelho */
  border: 1px solid #ff4d4d;
  /* Borda vermelha */
}

/* Ações com Prazo Próximo */
.due-date.due-soon {
  background-color: #fff8e6;
  /* Fundo amarelo claro */
  color: #ff9900;
  /* Texto laranja */
  border: 1px solid #ff9900;
  /* Borda laranja */
}

/* Responsividade */
@media (max-width: 768px) {
  .patient-action-row {
    flex-direction: column;
  }

  .patient-card-column,
  .actions-list-column {
    max-width: 100%;
  }
}

/* Ajustes Adicionais para o PatientCardNOTLIST */
.patient-card-component {
  /* Mantém o estilo existente ou ajuste conforme necessário */
  padding: 0px;
  /* Removido o padding para compactar */
  font-size: 0.9em;
  /* Fonte menor */
}

.patient-card-component .media-left .image {
  width: 48px;
  /* Reduzido de 64x64 para 48x48 */
  height: 48px;
}

.patient-card-component .media-content .content {
  padding: 5px;
  /* Reduzido de 10px para 5px */
}

.patient-card-component .patient {
  font-size: 1em;
  /* Fonte menor */
  font-weight: bold;
}

.patient-card-component .cid {
  font-size: 0.8em;
  /* Fonte menor */
  color: #666;
}

.patient-card-component .detail {
  font-size: 0.8em;
  /* Fonte menor */
  color: #888;
}

.patient-card-component .diagnostic,
.patient-card-component .painLevel,
.patient-card-component .serviceLocation {
  font-size: 0.8em;
  /* Fonte menor */
}

/* Pequenos ajustes para melhorar a compactação geral */
.action-card h3 {
  font-size: 1.1em;
  /* Fonte ligeiramente menor */
}
</style>
