import { ref } from "vue";
const dialogs = ref([]); // []
export default function () {
    function create({
        component,
        componentProps
    }) {
        if (!component) throw new Error("Prop component is required");
        if (!componentProps) throw new Error("Prop componentProps is required");
        const id = Math.floor(Math.random() * 9999999999); 


        dialogs.value.push({
            id,
            component,
            componentProps,
            close: function() {
                dialogs.value = dialogs.value.filter(e => e.id !== id);
            }
        });

    }
    return {
        dialogs,
        create
    };
}