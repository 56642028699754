<template>
  <div class="prescriptions">
    <lila-message :alert-data="alert" />
    <div
      v-if="isAdded && attachedPrescription"
      class="view-container"
    >
      <div 
        v-if="!isEditingPrescription" 
        class="prescription-container"
      >
        <figure
          v-if="isImage"
          class="image"
          @click="onClick"
        >
          <img :src="attachedPrescription">
        </figure>
        <embed
          v-else
          :src="attachedPrescription"
          type="application/pdf"
          class="view-pdf"
        >
      </div>
    </div>
    <div class="container">
      <div class="field-group">
        <button
          type="button"
          class="borderless btn-attachment"
          @click="openFolderDirectory"
        >
          <lila-svgIcon :src="require(`@assets/icons/attachment.svg`)" /> 
          <span v-if="!isAdded">Clique para Adicionar a Receita Médica do Paciente</span>
          <span v-else>Clique para Editar a Receita Médica do Paciente</span>
          
          <input
            ref="fileInputRef"
            type="file"
            class="file-input"
            @change="handleFileUpload"
          >
        </button>

        <div class="field-group-body">
          <lila-button
            type="submit"
            :title="'Salvar'"
            @click="submitPrescriptionInfo"
          />
        </div>
      </div>
    </div>

    <div class="comment-container">
      <b>COMENTÁRIOS</b>
      <div
        v-if="!isEditingComment"
        class="comment-content"
        @click="startEditComment"
      >
        {{ comment }}
      </div>
      <div v-else>
        <lila-textarea
          v-model="comment"
          placeholder="Informe seu comentário"
        />
        <lila-button
          type="submit"
          :title="'Salvar'"
          @click="submitPrescriptionInfo"
        />
      </div>
      <lila-button
        v-if="!isEditingComment"
        type="submit"
        :title="comment ? 'Editar Comentário' : 'Adicionar Comentário'"
        @click="startEditComment"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "../../../js/axios.js";
import { Session } from "../../../plugins/Session.js";
import { userSessionName } from "../../../js/axios.js";
import { useRoute } from "vue-router";
import LilaSvgIcon from "@components/shared/images/SvgIcon.vue";
import LilaTextarea from "@components/shared/fields/Textarea.vue";
import LilaButton from "@components/shared/button/Button.vue";
import LilaMessage from "@components/shared/message/Message.vue";
import useDialog from "@composables/useDialog.js";
import LilaImg from "../../../components/Common/LIlaImg.vue";

const {
    create
} = useDialog();
const route = useRoute();
// const loading = ref(false);
const alert = ref({
  message: "",
  type: "",
  show: false
});
const attachedPrescription = ref("");
const displayedComment = ref("");
const comment = ref(displayedComment.value);
const isImage = ref(false);
const isAdded = ref(false);
const fileInputRef = ref(); 
const patientId = route.params.id; 

const getPrescription = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `/api/prescription/patient/${patientId}`,
      headers: {
        Authorization: `bearer ${Session.get(userSessionName).token.Value}`
      }
    });

    const { Comment, AttachedPrescription } = response.data;

    comment.value = Comment || "";
    attachedPrescription.value = AttachedPrescription || null;

    if (AttachedPrescription) {
      isAdded.value = true;
      const imageExtensions = ["jpeg", "jpg", "gif", "png"];
      const fileExtension = AttachedPrescription.split(".").pop().toLowerCase();

      isImage.value = imageExtensions.includes(fileExtension);
    }
  } catch (error) {
    console.error(error);
    // alert.value.show = true;
    // alert.value.type = "error";
    // alert.value.message = "Erro ao obter a receita médica.";
  } finally {
    // loading.value = false;
  }
};
const isEditingPrescription = ref(false);
const isEditingComment = ref(false);
const editedComment = ref("");

const startEditComment = () => {
  isEditingComment.value = true;
  editedComment.value = comment.value;
};

const submitPrescriptionInfo = async () => {
 
  try {    
    const formData = new FormData();
    formData.append("Comment", comment.value);
    formData.append("AttachedPrescription", attachedPrescription.value);

    let response;
    if (isAdded.value) {
      response = await axios({
        method: "PUT",
        url: `/api/prescription/patient/${patientId}`,
        data: formData,
        headers: {
          Authorization: `bearer ${Session.get(userSessionName).token.Value}`,
          "Content-Type": "multipart/form-data"
        }
      });
    } else {
      response = await axios({
        method: "POST",
        url: `/api/prescription/patient/${patientId}`,
        data: formData,
        headers: {
          Authorization: `bearer ${Session.get(userSessionName).token.Value}`,
          "Content-Type": "multipart/form-data"
        }
      });
    }

    if (response.status === 200 || response.status === 204) {
      comment.value = editedComment.value;
      isEditingPrescription.value = false;
      isEditingComment.value = false;
      alert.value.show = true;
      alert.value.type = "success";
      alert.value.message = "O registro foi inserido com sucesso!";
      isAdded.value = true;
    }
    const button = document.querySelector(".btn-attachment");
      if (button) {
        button.classList.remove("attached-file");
    } 
  } catch (error) {
    alert.value.show = true;
    alert.value.type = "error";
    alert.value.message = "Erro ao adicionar a informação.";
  } finally {
    // loading.value = false;
    await getPrescription();
  }
};

function openFolderDirectory() {
  fileInputRef.value.click();
  isEditingPrescription.value = true;
}

const handleFileUpload = (event) => {
  const file = event.target.files[0];

  if (file) {
    const isValidFileType = /\.(pdf|jpeg|jpg|gif|png)$/i.test(file.name) || /^image\//i.test(file.type);

    if (!isValidFileType) {
      alert.value.show = true;
      alert.value.type = "error";
      alert.value.message = "Por favor, selecione um arquivo PDF ou uma imagem.";
      event.target.value = "";
      return;
    }

    isImage.value = /\.(jpeg|jpg|gif|png)$/i.test(file.name) || /^image\//i.test(file.type);
    attachedPrescription.value = file; 
  }

  const button = event.target.closest(".btn-attachment");
  if (button) {
    button.classList.add("attached-file");
  }
};

function onClick() {
    create({
        component: LilaImg,
        componentProps: {
            src: attachedPrescription,
            style: {
              width: "40%",
              alingSelf: "center",
              margin: "auto"
            }
        }
    });
}

onMounted(() => {
  getPrescription();
});
</script>

<style>
  .view-pdf {
    width: 100%;
    height: 500px; 
  }

  .prescriptions figure {
    cursor: pointer;
  }

   .prescriptions .btn-attachment {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }

  .prescriptions .btn-attachment svg, span {
    cursor: pointer;
  }

  .prescriptions .btn-attachment .a,
  .prescriptions .btn-attachment.attached-file .c {
      -webkit-transition: fill .3s ease-in-out;
              transition: fill .3s ease-in-out;
  }

  .prescriptions .btn-attachment.attached-file .a.attachment {
      fill: #46db8c;
  }

  .prescriptions .btn-attachment.attached-file .c.attachment {
      fill: #ffffff;
  }

  .comment-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #0000001C;
    background-color: white;
  }

  .comment-container p {
    margin-bottom: 10px; 
  }

  .comment-content {
    white-space: pre-wrap;
  }

  .prescription-container img {
    width: 100%;
  }
</style>
