<template>
  <div class="historyRecord">
    <lila-message :alert-data="alert" />

    <div
      class="field-group history-record-container"
      :class="[
        loadingContent ? 'loading large' : '',
        loadingContent || data.historyRecordList.length > 0
          ? 'history-record-container-default-height'
          : '',
      ]"
    >
      <div
        v-show="!loadingContent"
        class="field-group-header align-title"
      >
        <p
          v-show="data.historyRecordList.length == 0"
          class="semi-bold-text"
        >
          Você ainda não incluiu nenhum Registro.
        </p>
      </div>

      <div
        v-show="!loadingContent"
        class="field-group-body"
      >
        <lila-history-record-card
          :data-list="data.historyRecordList"
          :extended-description="true"
          card-size="is-12-desktop"
          @custom-click-edit-event="mountEditHistoryRecord"
          @custom-click-delete-event="openDeleteModal"
        />
      </div>
    </div>

    <lila-pagination
      v-show="!loadingContent && data.historyRecordList.length > 0"
      :pagination-config="pagination"
      @custom-change-page-event="changePage"
    />

    <form
      class="container basic-info-form medical-card-form history-record-form"
      @submit.prevent="submitForm()"
    >
      <div
        ref="historyRecordForm"
        class="field-group"
      >
        <div class="field-group-header align-title">
          <p class="field-group-label-type">
            Adicionar Registro
          </p>
        </div>

        <div class="field-group-body">
          <lila-select
            v-model="v.newHistoryRecord.HistoryRecordType.Id.$model"
            placeholder="Escolha o tipo do Registro"
            :options="historyRecordTypeList"
            :validation="v.newHistoryRecord.HistoryRecordType.Id"
            @custom-change-event="changeHistoryRecordType"
          />

          <div class="columns">
            <div class="column is-6">
              <lila-datepicker
                v-show="newHistoryRecord.HistoryRecordType?.Id != 4"
                v-model="v.newHistoryRecord.InitialDate.$model"
                :placeholder="newHistoryRecord.HistoryRecordType?.Id == 1 ? 'Data' : 'Data inicio'"
                :validation="v.newHistoryRecord.InitialDate"
              />
            </div>

            <div class="column is-6">
              <lila-datepicker
                v-show="newHistoryRecord.HistoryRecordType?.Id != 1 && newHistoryRecord.HistoryRecordType?.Id != 4"
                v-model="v.newHistoryRecord.EndDate.$model"
                placeholder="Data fim"
                :validation="v.newHistoryRecord.EndDate"
              />
            </div>
          </div>

          <lila-textarea
            v-show="newHistoryRecord.HistoryRecordType?.Id != 4"
            v-model="v.newHistoryRecord.HistoryRecordNotes.$model"
            placeholder="Anotações do Registro"
            :validation="v.newHistoryRecord.HistoryRecordNotes"
          />

          <lila-button
            type="submit"
            :title="newHistoryRecord.Id ? 'Editar' : 'Adicionar'"
            :second-button="newHistoryRecord.Id ? { type: 'button', title: 'Cancelar' } : {}"
            :loading="loading"
            @custom-click-cancel-event="clearHistoryRecord()"
          />
        </div>
      </div>
    </form>

    <div
      class="modal default-modal"
      :class="deleteModal.active ? 'is-active' : ''"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Excluir
          </p>
        </header>

        <section class="modal-card-body">
          <p>Deseja excluir esse registro?</p>
        </section>

        <footer class="modal-card-foot">
          <lila-button
            type="submit"
            title="Excluir"
            class="submit-buttons"
            :second-button="{ type: 'button', title: 'Cancelar', class: 'dark-lilas' }"
            @custom-click-event="deleteHistoryRecord(deleteModal.deleted)"
            @custom-click-cancel-event="closeDeleteModal()"
          />
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Select from '@components/shared/fields/Select.vue';
import Textarea from '@components/shared/fields/Textarea.vue';
import Button from '@components/shared/button/Button.vue';
import Message from '@components/shared/message/Message.vue';
import HistoryRecordCard from '@components/shared/lila/HistoryRecordCard.vue';
import PaginationComponent from '@components/shared/pagination/Pagination.vue';
import LilaDatepicker from '@components/shared/fields/LilaDatepicker.vue';
import { AlertMessage, AlertType, PatientJourneyOptions } from "@/enums/Index";

import useVuelidate from '@vuelidate/core';
import {
  required,
  requiredIf,
  email,
  helpers,
  maxLength,
} from "@vuelidate/validators";

import { utilities } from '@/plugins/UtilitiesV2';

import {
  Alert,
  FrontendHistoryRecord,
  HistoryRecordType,
  Pagination
} from '@/models';

// Import the HistoryRecord interface
import { HistoryRecord } from '@/models/historyRecord';

export default defineComponent({
  components: {
    LilaDatepicker,
    'lila-select': Select,
    'lila-textarea': Textarea,
    'lila-button': Button,
    'lila-message': Message,
    'lila-history-record-card': HistoryRecordCard,
    'lila-pagination': PaginationComponent,
  },
  data() {
    return {
      v: useVuelidate(),
      data: {
        id: Number(this.$route.params.id),
        historyRecordList: [] as FrontendHistoryRecord[],
      },
      newHistoryRecord: new FrontendHistoryRecord({
        Id: 0,
        PatientId: Number(this.$route.params.id),
        InitialDate: '',
        EndDate: '',
        HistoryRecordType: { Id: null, Name: '', Category: 0 },
        HistoryRecordNotes: '',
        deleteLoading: false,
        addNew: false
      }),

      historyRecordTypeList: [] as { _id: number; name: string }[],
      deleteModal: {
        active: false,
        deleted: null as FrontendHistoryRecord | null,
      },
      loadingContent: false,
      loading: false,
      alert: {
        message: '',
        type: {},
        show: false,
      } as Alert,
      pagination: {
        current: 0,
        totalPages: 0,
        perPage: 10,
      } as Pagination,
    };
  },
  validations() {
    return {
      newHistoryRecord: {
        InitialDate: {},
        EndDate: {},
        HistoryRecordNotes: {},
        HistoryRecordType: { required: required, Id: { required }, Name: {}, Category: {} },
      },
    };
  },
  watch: {
    $route(to) {
      this.data.id = Number(to.params.id);
      this.getContent();
    },
  },
  created() {
    this.getContent();
  },
  methods: {
    submitForm(): void {
      this.newHistoryRecord.addNew = true;

      this.v.$touch();

      if (this.v.$invalid) return;

      if (this.newHistoryRecord.Id) {
        this.editHistoryRecord(this.newHistoryRecord);
      } else {
        this.saveHistoryRecord(this.newHistoryRecord);
      }
    },
    async getContent(): Promise<void> {
      this.loadingContent = true;

      try {
        await this.getHistoryRecordType(); // Ensure this finishes first
        await this.getPatientHistoryRecordList(); // Then this runs after
      } catch (error: any) {
        utilities.verifyToken(error.response.status);
        this.$router.push({ name: 'myPatients' });
      } finally {
        this.loadingContent = false;
      }
    }
    ,
    getPatientHistoryRecordList(page: number = 1): Promise<void> {
      return this.$axios
        .get(
          `api/historyRecord/patient/${this.data.id}?page=${page}&limit=${this.pagination.perPage}`,
          {
            headers: {
              Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
            },
          }
        )
        .then((res: any) => {
          this.pagination.current = res.data.Pagination.CurrentPage;
          this.pagination.totalPages = res.data.Pagination.TotalPage;
          this.pagination.perPage = res.data.Pagination.ItemPerPage;

          this.data.historyRecordList = res.data.Data.map((item: HistoryRecord) =>
            this.formatDataFromAPItoFront(item)
          );

          let oi = 1;
        });
    },
    getHistoryRecordType(): Promise<void> {
      return this.$axios
        .get('api/historyRecordtype', {
          headers: {
            Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
          },
        })
        .then((res: any) => {
          this.historyRecordTypeList = res.data.map((item: HistoryRecordType) => {
            return {
              _id: item.Id,
              name: item.Name,
            };
          });
        });
    },
    changePage(page: number, keepAlert: boolean = false): void {
      if (!keepAlert) this.alert.show = false;
      this.loadingContent = true;

      Promise.all([this.getPatientHistoryRecordList(page)])
        .catch((error) => {
          utilities.verifyToken(error.response.status);
          console.error(error);
          this.alert.type = AlertType.DANGER;
          this.alert.message = AlertMessage.ERROR;
          if (error && error.response) {
            utilities.verifyToken(error.response.status);
            if (error.response.data) {
              utilities.controlAlert(error.response.data, this.alert);
            } else {
              utilities.controlAlert(
                "Ocorreu um erro desconhecido.",
                this.alert
              );
            }
          } else {
            utilities.controlAlert("Ocorreu um erro desconhecido.", this.alert);
          }
        })
        .finally(() => (this.loadingContent = false));
    },
    saveHistoryRecord(historyRecord: FrontendHistoryRecord): Promise<void> {
      this.loading = true;

      return this.$axios({
        method: 'POST',
        url: `api/historyRecord/patient/${this.data.id}`,
        data: this.formatDataFromFrontToAPI(historyRecord),
        headers: {
          Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
        },
      })
        .then((res: any) => {
          this.data.historyRecordList.push(this.formatDataFromAPItoFront(res.data));
          this.changePage(this.pagination.current);
          this.clearHistoryRecord();

          // Show success alert
          this.alert.type = AlertType.SUCCESS;
          this.alert.message = AlertMessage.SAVE;
          utilities.controlAlert(null, this.alert);
        })
        .catch((error) => {
          // Handle errors
          utilities.verifyToken(error.response.status);
          console.error("API Error:", error);

          this.alert.type = AlertType.DANGER;

          if (error.response && error.response.data) {
            // Extrair a mensagem de erro padrão
            let apiMessage = error.response.data.Message || "Erro ao salvar paciente.";

            // Verifica se o array de Errors existe e não está vazio
            if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
              // Concatena todas as mensagens de erro do array Errors
              const errorMessages = error.response.data.Errors.join(" ");
              this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
            } else {
              // Se não houver array de Errors, usa a mensagem padrão
              this.alert.message = apiMessage;
            }

            utilities.controlAlert(this.alert.message, this.alert);
          } else if (error.message) {
            // Use the error message if it exists (e.g., network errors)
            this.alert.message = error.message;

            utilities.controlAlert(this.alert.message, this.alert);
          } else {
            // Default unknown error message
            this.alert.message = "Ocorreu um erro desconhecido.";

            utilities.controlAlert(this.alert.message, this.alert);
          }
        })
        .finally(() => (this.loading = false));
    },
    updateFrontendHistoryRecordType(frontendHistoryRecord: FrontendHistoryRecord): void {
      if (frontendHistoryRecord.HistoryRecordType) {
        frontendHistoryRecord.HistoryRecordType.Name = this.getHistoryRecordNameById(frontendHistoryRecord.HistoryRecordType.Id ?? null);
        frontendHistoryRecord.HistoryRecordTypeId = frontendHistoryRecord.HistoryRecordType.Id;
      }
    },
    editHistoryRecord(frontendHistoryRecord: FrontendHistoryRecord): Promise<void> {
      this.loading = true;

      this.updateFrontendHistoryRecordType(frontendHistoryRecord);

      return this.$axios({
        method: 'PUT',
        url: `api/historyRecord/${frontendHistoryRecord.Id}/patient/${this.data.id}`,
        data: this.formatDataFromFrontToAPI(frontendHistoryRecord),
        headers: {
          Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
        },
      })
        .then(() => {
          const index = this.data.historyRecordList.findIndex(
            (item) => item.Id == frontendHistoryRecord.Id
          );
          if (index != -1) {
            this.data.historyRecordList.splice(index, 1, frontendHistoryRecord);
          }

          // Show success alert
          this.alert.type = AlertType.SUCCESS;
          this.alert.message = AlertMessage.EDIT;
          utilities.controlAlert(null, this.alert);
          this.clearHistoryRecord();
        })
        .catch((error) => {
          // Handle errors
          utilities.verifyToken(error.response.status);
          console.error("API Error:", error);

          this.alert.type = AlertType.DANGER;

          if (error.response && error.response.data) {
            // Extrair a mensagem de erro padrão
            let apiMessage = error.response.data.Message || "Erro ao salvar paciente.";

            // Verifica se o array de Errors existe e não está vazio
            if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
              // Concatena todas as mensagens de erro do array Errors
              const errorMessages = error.response.data.Errors.join(" ");
              this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
            } else {
              // Se não houver array de Errors, usa a mensagem padrão
              this.alert.message = apiMessage;
            }

            utilities.controlAlert(this.alert.message, this.alert);
          } else if (error.message) {
            // Use the error message if it exists (e.g., network errors)
            this.alert.message = error.message;

            utilities.controlAlert(this.alert.message, this.alert);
          } else {
            // Default unknown error message
            this.alert.message = "Ocorreu um erro desconhecido.";

            utilities.controlAlert(this.alert.message, this.alert);
          }
        })
        .finally(() => (this.loading = false));
    },
    deleteHistoryRecord(deletedHistoryRecord: FrontendHistoryRecord | null): void {
      if (!deletedHistoryRecord) {
        console.error('No history record provided for deletion.');
        return;
      }

      this.alert.show = false;

      deletedHistoryRecord.deleteLoading = true;

      this.closeDeleteModal();

      this.$axios
        .delete(
          `/api/historyRecord/${deletedHistoryRecord.Id}/patient/${this.data.id}`,
          {
            headers: {
              Authorization: `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
            },
          }
        )
        .then(() => {
          this.data.historyRecordList = this.data.historyRecordList.filter(
            (item) => item.Id != deletedHistoryRecord.Id
          );
          this.changePage(this.pagination.current, true);

          utilities.controlAlert(null, this.alert, 'delete');
        })
        .catch((error) => {
          deletedHistoryRecord.deleteLoading = false;
          // Handle errors
          utilities.verifyToken(error.response.status);
          console.error("API Error:", error);

          this.alert.type = AlertType.DANGER;

          if (error.response && error.response.data) {
            // Extrair a mensagem de erro padrão
            let apiMessage = error.response.data.Message || "Erro ao salvar paciente.";

            // Verifica se o array de Errors existe e não está vazio
            if (error.response.data.Errors && Array.isArray(error.response.data.Errors)) {
              // Concatena todas as mensagens de erro do array Errors
              const errorMessages = error.response.data.Errors.join(" ");
              this.alert.message = `${apiMessage} Detalhes: ${errorMessages}`;
            } else {
              // Se não houver array de Errors, usa a mensagem padrão
              this.alert.message = apiMessage;
            }

            utilities.controlAlert(this.alert.message, this.alert);
          } else if (error.message) {
            // Use the error message if it exists (e.g., network errors)
            this.alert.message = error.message;

            utilities.controlAlert(this.alert.message, this.alert);
          } else {
            // Default unknown error message
            this.alert.message = "Ocorreu um erro desconhecido.";

            utilities.controlAlert(this.alert.message, this.alert);
          }
        });
    },
    // Formata dados recebidos da API para serem consumidos no Front.
    formatDataFromAPItoFront(historyRecordAsJSON: HistoryRecord): FrontendHistoryRecord {
      const initialDateFormatted = historyRecordAsJSON.InitialDate
        ? utilities.formatDate(
          historyRecordAsJSON.InitialDate,
          "YYYY-MM-DD", // formato que vem da API
          "DD/MM/YYYY"
        )
        : '';
      const endDateFormatted = historyRecordAsJSON.EndDate
        ? utilities.formatDate(
          historyRecordAsJSON.EndDate,
          "YYYY-MM-DD",
          "DD/MM/YYYY"
        )
        : '';

      const formattedDate =
        initialDateFormatted + (endDateFormatted ? ` - ${endDateFormatted}` : '');

      const historyRecord = new FrontendHistoryRecord({
        ...historyRecordAsJSON,
        deleteLoading: false,
        addNew: false,
      });

      historyRecord.InitialDate = initialDateFormatted;
      historyRecord.EndDate = endDateFormatted;
      historyRecord.HistoryRecordTypeId = historyRecordAsJSON.HistoryRecordType?.Id;
      if (historyRecord.HistoryRecordType && historyRecordAsJSON.HistoryRecordType) {
        historyRecord.HistoryRecordType.Name = this.getHistoryRecordNameById(historyRecordAsJSON.HistoryRecordType.Id ?? null);
      }


      return historyRecord;
    },
    // Método para formatar dados na hora de enviar pra API
    formatDataFromFrontToAPI(historyRecord: FrontendHistoryRecord): HistoryRecord {
      const historyRecordAsJSON: HistoryRecord = {
        HistoryRecordTypeId: historyRecord.HistoryRecordType?.Id, // lembrando que o bind é nesse cara

        HistoryRecordNotes: historyRecord.HistoryRecordNotes,
        InitialDate: historyRecord.InitialDate
          ? utilities.formatDate(
            historyRecord.InitialDate,
            'DD/MM/YYYY',
            'MM/DD/YYYY'
          )
          : undefined,
        EndDate: historyRecord.EndDate
          ? utilities.formatDate(historyRecord.EndDate, 'DD/MM/YYYY', 'MM/DD/YYYY')
          : undefined,

      } as HistoryRecord;

      historyRecord.HistoryRecordTypeId = historyRecordAsJSON.HistoryRecordType?.Id;
      if (historyRecord.HistoryRecordType && historyRecordAsJSON.HistoryRecordType) {
        historyRecord.HistoryRecordType.Name = this.getHistoryRecordNameById(historyRecordAsJSON.HistoryRecordType.Id ?? null);
      }

      return historyRecordAsJSON;
    },
    mountEditHistoryRecord(historyRecord: FrontendHistoryRecord): void {
      (this.$refs.historyRecordForm as HTMLElement).scrollIntoView();

      this.newHistoryRecord = new FrontendHistoryRecord(historyRecord);
    },
    clearHistoryRecord(): void {
      this.newHistoryRecord = {
        addNew: false,
        Id: 0,
        PatientId: Number(this.$route.params.id),
        HistoryRecordTypeId: 0,
        InitialDate: '',
        EndDate: '',
        HistoryRecordType: { Id: null, Name: '', Category: 0 },
        HistoryRecordNotes: '',
        deleteLoading: false
      } as FrontendHistoryRecord;

      this.v.$reset();
    },
    getHistoryRecordNameById(historyRecordId: number | null): string {
      const historyRecord = this.historyRecordTypeList.find(
        (item) => item._id == historyRecordId
      );

      return historyRecord ? historyRecord.name : '- - -';
    },
    changeHistoryRecordType(historyRecordTypeId: number | null): void {
      if (historyRecordTypeId == 1) this.newHistoryRecord.EndDate = '';
      if (historyRecordTypeId == 4) {
        this.newHistoryRecord.InitialDate = '';
        this.newHistoryRecord.EndDate = '';
        this.newHistoryRecord.HistoryRecordNotes = '';
      }
    },
    openDeleteModal(deletedHistoryRecord: FrontendHistoryRecord): void {
      this.deleteModal.active = true;
      this.deleteModal.deleted = deletedHistoryRecord;
    },
    closeDeleteModal(): void {
      this.deleteModal.active = false;
    },
  },
});
</script>

<style scoped>
@import '~@assets/css/views/historyRecord.css';
</style>
