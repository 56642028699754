<template>
  <div class="popupActionOutcomes">
    <div class="popupActionOutcomes-content">
      <h2>Detalhe o desfecho para "{{ draggedCard?.title }}"</h2>
     
      <textarea
        v-model="outcomeObservations"
        placeholder="Desfecho"
      />
      <div class="popupActionOutcomes-button-group">
        <button
          id="popupActionOutcomes-save-button"
          @click="saveOutcome"
        >
          Salvar
        </button>
        <button
          id="popupActionOutcomes-cancel-button"
          @click="$emit('close')"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    draggedCard: {
      type: Object,
      default: () => ({}) // Default value as an empty object
    },
    mappedOutcomeOptions: {
      type: Array,
      default: () => [] // Default value as an empty array
    },
    doneActions: {
      type: Array,
      default: () => [] // Default value as an empty array
    },
    pendingActions: {
      type: Array,
      default: () => [] // Default value as an empty array
    },
    alert: {
      type: Object,
      default: () => ({}) // Default value as an empty object
    }
  },
  emits: ['close'], // Declare the "close" event

  data() {
    return {
      selectedOutcome: null,
      outcomeObservations: ""
    };
  },
  methods: {
    saveOutcome() {
      this.draggedCard.actionOutcomeObservations = this.outcomeObservations;
      this.draggedCard.actionOutcomeId = this.selectedOutcome;
      this.draggedCard.conclusionDate = this.$Utilities.formatDate(new Date().toLocaleDateString());
      this.completeAction(this.draggedCard);

      this.$emit('close'); // Emit the "close" event
    },
    async completeAction(card) {
      await this.$HTTPUtilities.put(
        this,
        `/api/action/${card.Id}/patient/${this.$route.params.id}`,
        this.mountCompleteActionJSON(card),
        () => {
          card.status = 1;
          this.doneActions.push(card);
          this.removeCardFromPendingList(card);
        },
        this.alert
      );
    },
    mountCompleteActionJSON(actionCard) {
      return {
        Status: 1,
        ActionOutcomeId: actionCard.actionOutcomeId,
        ActionOutcomeObservations: actionCard.actionOutcomeObservations
      };
    },
    removeCardFromPendingList(card) {
      const index = this.pendingActions.findIndex(
        (action) => action.Id === card.Id
      );
      if (index !== -1) this.pendingActions.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.popupActionOutcomes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popupActionOutcomes-content {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.popupActionOutcomes-content h2 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.popupActionOutcomes-content textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.popupActionOutcomes-button-group {
  display: flex;
  justify-content: space-between;
}

#popupActionOutcomes-save-button,
#popupActionOutcomes-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}

#popupActionOutcomes-save-button {
  background-color: #4a148c;
  color: white;
}

#popupActionOutcomes-cancel-button {
  background-color: #f5f5f5;
  color: #333;
}

#popupActionOutcomes-save-button:hover {
  background-color: #4a148c;
}

.popupActionOutcomes .select-outcome {
  width: 100%;
  box-shadow: 0px 1px 6px #00000024;
  border-radius: 20px;
  margin: 0;
}
</style>
