<template>
  <div
    v-if="showPopup"
    class="popup"
  >
    <div class="popup-content">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: true,
      message: ""
    };
  },
  mounted() {
    // Lógica para calcular a quantidade de dias até o uso da aplicação
    
    const diasRestantes = this.$Session.get(this.$userSessionName).trial;
    
    this.message = `Faltam ${diasRestantes} dias para expirar seu período de teste.`;

  },
  methods: {
    closePopup() {
      this.showPopup = false;
    }
  }
  
};
</script>

<style scoped>

.popup {
  width: 100%;
  height: 5%;
  background-color: #ae82b9; 
  color: #DCDCDC; 
  font-weight: bold;
  padding: 10px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.popup-content {
  text-align: center;
}

</style>

