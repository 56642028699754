import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "history-record-card history-record-card-component" }
const _hoisted_2 = { class: "columns is-multiline" }
const _hoisted_3 = { class: "timeline-container" }
const _hoisted_4 = {
  key: 0,
  class: "timeline-date"
}
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "type" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { class: "box-buttons" }
const _hoisted_10 = { class: "buttons" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (historyRecord, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `${historyRecord.Id}-${index}`,
          class: _normalizeClass(["column", _ctx.cardSize])
        }, [
          _createElementVNode("div", _hoisted_3, [
            (historyRecord.InitialDate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(historyRecord.InitialDate), 1))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "timeline-line" }, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "timeline-marker" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(historyRecord.HistoryRecordType?.Name || '-'), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(historyRecord.formattedDate), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.descriptionSlice(historyRecord.HistoryRecordNotes)), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "button is-rounded default",
                    onClick: ($event: any) => (_ctx.clickEditEvent(historyRecord, index))
                  }, " Editar ", 8, _hoisted_11),
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["button is-rounded cancel", historyRecord.deleteLoading ? 'is-loading' : '']),
                    onClick: ($event: any) => (_ctx.clickDeleteEvent(historyRecord, index))
                  }, " Excluir ", 10, _hoisted_12)
                ])
              ])
            ])
          ])
        ], 2))
      }), 128))
    ])
  ]))
}