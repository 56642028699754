import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "patient-card patient-card-component" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "media" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "image is-64x64" }
const _hoisted_6 = { class: "is-profile-picture-64x64" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "media-content" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "content-line" }
const _hoisted_11 = { class: "patient" }
const _hoisted_12 = { class: "cid" }
const _hoisted_13 = { class: "detail" }
const _hoisted_14 = {
  key: 0,
  class: "painLevel"
}

import { onMounted } from 'vue';
  import { FrontendPatient, PatientCID, PatientAttributeOption } from "@/models";
  import { utilities } from "@/plugins/UtilitiesV2";
  
  // Definição das Props usando tipagem genérica
  
export default /*@__PURE__*/_defineComponent({
  __name: 'PatientCardNOTLIST',
  props: {
    patient: {},
    routerPath: {}
  },
  emits: ["customClickEvent"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  
  // Definição dos Eventos Emitidos
  const emit = __emit;
  
  // Métodos
  
  /**
   * Emite um evento personalizado quando o cartão é clicado.
   */
  const handleClick = () => {
    emit('customClickEvent', props.patient);
  };
  
  /**
   * Corta a mensagem se exceder 75 caracteres.
   * @param message A mensagem a ser cortada.
   * @returns A mensagem cortada ou original.
   */
  const messageSlice = (message?: string | null): string => {
    if (message && message.length > 75) {
      return `${message.substring(0, 75)}...`;
    }
    return message || '';
  };
  
  /**
   * Obtém o CID display a partir da lista de CIDs do paciente.
   * @param cids A lista de CIDs do paciente.
   * @returns Uma string representando os CIDs.
   */
  const getDisplayCid = (cids: PatientCID[] | null): string => {
    if (!cids || cids.length === 0) return '';
    return cids.map(cid => cid.CID.CIDCode).join(', ');
  };
  
  /**
   * Safe wrapper para getAbbrevGender para lidar com undefined ou null
   * @param gender O gênero do paciente
   * @returns Gênero abreviado ou string vazia
   */
  const getAbbrevGenderSafe = (gender?: string | null): string => {
    return utilities.getAbbrevGender(gender ?? '');
  };
  
  /**
   * Safe wrapper para getAge para lidar com undefined ou null
   * @param birthDate A data de nascimento do paciente
   * @returns Idade ou string vazia
   */
  const getAgeSafe = (birthDate?: string | null): string => {
    if (birthDate) {
      return utilities.getAge(birthDate);
    }
    return '';
  };
  
  // Hook do Ciclo de Vida
  onMounted(() => {
    console.log('Props recebidas:', props.patient, props.routerPath);
  });
  
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "card",
      onClick: handleClick
    }, [
      _createVNode(_component_router_link, {
        to: { name: _ctx.routerPath, params: { id: _ctx.patient.Id } }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("article", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("figure", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("img", {
                      src: _ctx.patient.Photo || _unref(utilities).getDefaultProfilePicture(_ctx.patient.Gender),
                      alt: "Foto do paciente"
                    }, null, 8, _hoisted_7)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.patient.Name), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.patient.displayCid), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(getAbbrevGenderSafe(_ctx.patient.Gender)) + _toDisplayString(_ctx.patient.BirthDate ? `, ${getAgeSafe(_ctx.patient.BirthDate)}` : ""), 1),
                  (_ctx.patient.PS)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[0] || (_cache[0] = _createTextVNode(" Dor: ")),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.patient.PS), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
}

})