export enum PatientJourneyOptions {
    Desconhecido = 0,
    EmTratamento = 1,
    ForaDeTratamento = 2,
    Falecido = 3,
    Suspeicao = 4,
    Diagnostico = 5,
    Acompanhamento = 6,
    Recidiva = 7,
    CuidadosPaliativos = 8,
    Outro = 9
}