  <template>
  <div class="barriers">
    <div class="barriers-last-update">
      <p v-if="lastUpdate">
        Atualizado em {{ lastUpdate }}
      </p>
      <p v-else>
        Nenhuma barreira cadastrada
      </p>
    </div>

    <lila-message :alert-data="alertData" />

    <div>
      <div
        v-for="(barriers, category) in categoriesMap"
        :key="category"
        class="barriers-form-section"
      >
        <h3 @click="toggleCategory(category)">
          {{ category }}
          <span v-if="!categoryStates[category]">+</span>
          <span v-else>-</span>
        </h3>

        <div v-if="categoryStates[category]">
          <div
            v-for="barrier in barriers"
            :key="barrier.Id"
            class="checkbox-wrapper"
          >
            <div class="barrier-header" style="position: relative;">
              <lila-checkbox
                v-model="form[barrier.Id].selected"
                :title="barrier.Name"
                :preCheck="!optionAlreadySaved(barrier.Id)"
                class="barrier-option"
              />
              <!-- Botão de Três Pontinhos -->
              <button
                v-if="form[barrier.Id].selected && optionAlreadySaved(barrier.Id)"
                class="more-options"
                @click.stop="toggleMenu(barrier.Id)"
                aria-label="Mais opções"
              >
                &#x22EE;
              </button>

              <!-- Menu Contextual -->
              <div
                v-if="openMenuId === barrier.Id"
                class="action-menu"
              >
                <button
                  class="action-menu-item"
                  @click="openActionModal(barrier.BarrierCategory.Id, form[barrier.Id]); closeMenu()"
                >
                  <!-- Ícone de + -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="add-icon"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  <span>Adicionar ação</span>
                </button>
              </div>
            </div>
            <div
              v-if="form[barrier.Id].selected"
              class="description-wrapper"
            >
              <textarea
                v-model="form[barrier.Id].description"
                placeholder="Observações"
                class="barrier-description"
                maxlength="1020">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="barriers-footer">
      <button
        class="button white-button rounded"
        @click="showHistory"
      >
        Histórico
      </button>
      <button
        class="button default rounded"
        @click="saveBarrier"
      >
        Salvar
      </button>
    </div>

    <lila-barriers-history
      v-if="showBarriersHistory"
      :barriers="barriersHistory"
      @close="showHistory"
    />

    <!-- Componente de Criação de Ações -->
    <CreateActionFromBarrierPopup
      v-if="showCreateActionPopup"
      :categoryId="currentCategoryId"
      :patientBarrierItemId="currentPatientBarrierItem.Id"
      :alert="alertData"
      :professionals="professionals"
      @close="closeCreateActionPopup"
      @action-added="handleActionAdded"
    />
  </div>
</template>

  <script setup>
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import axios, { userSessionName } from "../../../js/axios";
import { useRoute } from "vue-router";
import { Session } from "../../../plugins/Session";
import { Utilities } from "../../../plugins/Utilities.js";
import LilaCheckbox from "@components/shared/fields/Checkbox.vue";
import LilaMessage from "@components/shared/message/Message.vue";
import LilaBarriersHistory from "@components/Barriers/LilaBarriersHistory.vue"; 
import CreateActionFromBarrierPopup from "@/components/Actions/CreateActionFromBarrierPopup.vue"; // Importando o componente de criação de ações

// Dados reativos
const form = ref({});
let formOptionsAlreadySaved = ref({});
const categoriesMap = ref({});
const alertData = ref({
  show: false,
  type: "",
  message: ""
});
const categoryStates = ref({});
const showBarriersHistory = ref(false);
const barriersHistory = ref([]);
const showCreateActionPopup = ref(false);
const currentCategoryId = ref(null);
const pendingActions = ref([]);
const professionals = ref([]);
const currentPatientBarrierItem = ref(null);
const lastUpdate = ref("");

// Novo estado para controlar qual menu está aberto
const openMenuId = ref(null);

const route = useRoute();
const patientId = route.params.id;

async function getCurrentPatientBarriers() {
  try {
    const response = await axios.get(
      `/api/barrier/patient/${patientId}`,
      { headers: { "Authorization": `bearer ${Session.get(userSessionName).token.Value}` } }
    );

    const patientBarriers = response.data;

    // Verifica se há barreiras e se existe uma data válida
    if (patientBarriers && patientBarriers.BarrierItems.length > 0) {
      lastUpdate.value = Utilities.formatDateTimeAndMinutes(patientBarriers.DateAndTime);

      // Marca os checkboxes correspondentes no form e atribui a descrição, se houver
      patientBarriers.BarrierItems.forEach(item => {

        form.value[item.BarrierType.Id] = {
          selected: true, // Inicializa como selecionado se o paciente já tiver a barreira
          description: item.Description || "",
          Id: item.Id
        };

        formOptionsAlreadySaved.value[item.BarrierType.Id] = {
          selected: true, 
          Id: item.Id
        };
      });
    } else {
      lastUpdate.value = null;
    }

  } catch (error) {
    console.error(error);
    Utilities.verifyToken(error.response?.status);
    Utilities.controlAlert(error.response?.data, alertData.value, "danger", "Erro ao buscar barreiras atuais");
  }
}

async function getBarrierTypes() {
  try {
    const response = await axios.get(
      "/api/barrier/types",
      { headers: { "Authorization": `bearer ${Session.get(userSessionName).token.Value}` } }
    );
    const barrierTypes = response.data.barrierTypes;

    // Mapeia as categorias e suas barreiras
    barrierTypes.forEach((barrier) => {
      const category = barrier.BarrierCategory.Name;
      if (!categoriesMap.value[category]) {
        categoriesMap.value[category] = [];
        categoryStates.value[category] = true; // Inicializa as categorias como expandidas
      }

      // Adiciona o barrier à categoria correspondente
      categoriesMap.value[category].push(barrier);

      // Inicializa o form com o Id de cada barrier
      if (form.value[barrier.Id] === undefined) {
        form.value[barrier.Id] = {
          selected: false,
          description: ""
        };
      }
    });

    await getCurrentPatientBarriers();


  } catch (error) {
    console.error(error);
    Utilities.verifyToken(error.response?.status);
    Utilities.controlAlert(error.response?.data, alertData.value, "danger", "Erro ao buscar tipos de barreiras");
  }
}

 function optionAlreadySaved(barrierTypeId){

  return Object.entries(formOptionsAlreadySaved.value).some(([selectedBarrierTypeId, barrierData]) => selectedBarrierTypeId == barrierTypeId);

}

async function saveBarrier() {
  const selectedBarriers = Object.entries(form.value)
    .filter(([barrierId, barrierData]) => barrierData.selected)
    .map(([barrierId, barrierData]) => ({
      BarrierTypeId: barrierId,
      Description: barrierData.description || ""
    }));

  const payload = {
    BarrierItems: selectedBarriers
  };

  try {
    await axios.post(`/api/barrier/patient/${patientId}`, payload, {
      headers: { "Authorization": `bearer ${Session.get(userSessionName).token.Value}` }
    });

    // Atualiza a última data e hora de atualização após salvar
    lastUpdate.value = Utilities.formatDateTimeAndMinutes(new Date());

    formOptionsAlreadySaved.value = {};

    selectedBarriers.forEach(barrier => {
      const barrierTypeId = barrier.BarrierTypeId;
      const formData = form.value[barrierTypeId];

      
      formOptionsAlreadySaved.value[barrierTypeId] = {
        selected: true,     
        Id: formData.Id                 
      };
    });

    Utilities.controlAlert(null, alertData.value, "success", "Barreiras salvas com sucesso!");
  } catch (error) {
    console.error(error);
    Utilities.verifyToken(error.response?.status);
    Utilities.controlAlert(error.response?.data, alertData.value, "danger", "Erro ao salvar barreiras");
  }

 }

async function loadProfessionals() {
  try {
    const response = await axios.get("/api/professional", {
      headers: {
        Authorization: `bearer ${Session.get(userSessionName).token.Value}`,
      },
    });
    response.data.forEach((professional) => {
      professionals.value.push({
        Id: professional.Id,
        Name: professional.Name,
        Photo: professional.Photo,
      });
    });
  } catch (error) {
    console.error(error);
    alertData.value = {
      show: true,
      type: "danger",
      message: "Erro ao carregar profissionais",
    };
  }
}

const showHistory = () => {
  showBarriersHistory.value = !showBarriersHistory.value;
};

const toggleCategory = (category) => {
  categoryStates.value[category] = !categoryStates.value[category];
};

// Métodos para o menu contextual
const toggleMenu = (barrierId) => {
  if (openMenuId.value === barrierId) {
    openMenuId.value = null;
  } else {
    openMenuId.value = barrierId;
  }
};

const closeMenu = () => {
  openMenuId.value = null;
};

const handleClickOutside = (event) => {
  const menu = document.querySelector(".action-menu");
  const button = document.querySelector(".more-options");
  if (menu && !menu.contains(event.target) && button && !button.contains(event.target)) {
    closeMenu();
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
  getBarrierTypes();
  loadProfessionals();
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Funções para a criação de ações
const openActionModal = (categoryId, patientBarrierItem) => {
  currentCategoryId.value = categoryId;
  currentPatientBarrierItem.value = patientBarrierItem;
  showCreateActionPopup.value = true;
};

const closeCreateActionPopup = () => {
  showCreateActionPopup.value = false;
  currentCategoryId.value = null;
};

const handleActionAdded = (newAction) => {
  pendingActions.value.push(newAction);
};
</script>

  <style scoped>
.barriers {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
}

.barriers-last-update {
  margin-bottom: 15px;
}

.barriers-last-update p {
  font-size: 14px;
  font-family: "Montserrat";
  color: #4a4a4a;
}

.barriers-form-section {
  margin-bottom: 20px;
}

.barriers-form-section h3 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4f3f82;
  margin-bottom: 25px;
}

.barriers-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.rounded {
  border-radius: 30px;
}

.white-button {
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #9a397d;
  color: #9a397d !important;
}

.white-button:hover,
.white-button:active,
.white-button:focus {
  background-color: transparent;
  border: 1px solid #9a397d;
  color: #9a397d;
}

.more-options {
  cursor: pointer;
  margin-left: auto;
  color: #6a1b9a;
  font-size: 24px;
  background: none;
  border: none;
  padding: 0;
  transform: rotate(90deg);
}

.more-options:hover {
  color: #4a148c;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  margin-bottom: 20px; 
}

.barrier-header {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative; 
}

.barrier-option {
  margin-right: 15px;
}

.description-wrapper {
  display: flex;
  flex-direction: column; 
  flex-grow: 1; 
  margin-top: 5px;
}

.barrier-description {
  width: 100%;
  min-width: 600px;
  max-width: 100%; 
  min-height: 80px;
  max-height: 150px;
  padding: 12px;
  font-size: 15px;
  line-height: 1.5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px; 
  background-color: #fafafa;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  resize: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.barrier-description:focus {
  border-color: #6200ee; 
  box-shadow: 0 0 0 3px rgba(98, 0, 238, 0.2);
  outline: none;
}

.barrier-description::placeholder {
  color: #888;
  font-size: 14px;
}

/* Estilos para o menu contextual */
.action-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px 0;
  z-index: 1000;
  top: 30px; 
  right: 0; 
  width: 180px; 
}

.action-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #4f3f82; 
}

.action-menu-item:hover {
  background-color: #f0f0f0;
}

.add-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px; 
  color: #9A397D; 
  flex-shrink: 0;
}
</style>
