<template>
  <div :class="[ loading ? 'loading large' : '']">
    <lila-message :alert-data="alert" />

    <!-- Kanban Section -->
    <div class="kanban">
      <!-- Coluna de Pendentes -->
      <div
        class="kanban-column"
        @dragover.prevent
        @drop="onDrop('pendingActions')"
      >
        <h2 class="kanban-pending-header">
          Pendente
        </h2>
        <div
          v-for="(card, index) in pendingActions"
          :key="index"
          ref="kanbanCard"
          :style="{ color: card.color }"
          :class="['card']"
          draggable="true"
          @dragstart="onDragStart(card, 'pendingActions')"
        >
          <div class="kanban-card-content">
            <h3>{{ card.title }}</h3>
            <div class="kanban-card-content-bottom">
              <div class="dispose-itens-horizontally">
                <img
                  :src="
                    card.assignedProfessional?.Photo ||
                      require('@/assets/images/default_avatar_neutral.png')
                  "
                  class="circular-mask small-image-25 is-cursor-pointer"
                  @click.stop="toggleProfessionalsDropdownInCard(card.Id, index)"
                >
                <p
                  id="assigned-professional-name"
                  @click.stop="toggleProfessionalsDropdownInCard(card.Id, index)"
                >
                  {{ card.assignedProfessional?.Name }}
                </p>
              </div>
              <p
                v-show="card.dueDate"
                id="action-due-date"
                @click.stop="toggleCalendarDropdownInCard(card.Id, index)"
              >
                Prazo: {{ $Utilities.formatDate(card.dueDate, "YYYY-MM-DD", "DD/MM") }}
              </p>
            </div>

            <!-- Lista de profissionais (dropdown) para o card -->
            <ul
              v-if="professionalsDropdownVisibleForCard[card.Id]"
              class="professionals-assigned-dropdown-list-on-card"
              @click.stop
            >
              <li
                v-for="professional in professionals"
                :key="professional.Id"
                class="dropdown-item"
                @click="selectProfessionalForCard(card, professional)"
              >
                {{ professional.Name }}
              </li>
            </ul>

            <!-- calendário para editar dueDate direto no card -->
            <vue-cal
              v-if="calendarDropdownVisibleForCard[card.Id]"
              id="update-due-date-calendar"
              class="vuecal--date-picker small-calendar vuecal--purple-theme calendar-position"
              xsmall
              hide-view-selector
              :time="false"
              :transitions="false"
              active-view="month"
              locale="pt-br"
              :disable-views="['week']"
              @cell-click="(date) => selectDueDateForCard(date, card)"
            />
          </div>
        </div>
      </div>

      <!-- Coluna de Concluídos -->
      <div
        class="kanban-column"
        @dragover.prevent
        @drop="onDrop('doneActions')"
      >
        <h2 class="kanban-done-header">
          Feito
        </h2>
        <div
          v-for="(card, index) in doneActions"
          :key="index"
          :style="{ color: card.color }"
          :class="['card']"
          draggable="true"
          @dragstart="onDragStart(card, 'doneActions')"
        >
          <div
            class="kanban-card-content is-cursor-pointer"
            @click="openClosedActionDetails(card)"
          >
            <h3>{{ card.title }}</h3>
          </div>
        </div>
      </div>
    </div>

    <!-- Botão para abrir o popup de ações -->
    <lila-button
      id="addAction"
      type="button"
      title="Adicionar"
      @click="showNewActionPopup = true"
    />

    <!-- Popup para adicionar ações -->
    <CreateActionPopup
      v-if="showNewActionPopup"
      :pending-actions="pendingActions"
      :alert="alert"
      :professionals="professionals"
      @close="showNewActionPopup = false"
    />

    <!-- Popup para definir desfecho -->
    <ActionOutcomePopup
      v-if="showOutcomePopup"
      :dragged-card="draggedCard"
      :mapped-outcome-options="mappedOutcomeOptions"
      :pending-actions="pendingActions"
      :done-actions="doneActions"
      :alert="alert"
      @close="showOutcomePopup = false"
    />

    <!-- Popup para exibir ação encerrada -->
    <DoneActionPopup
      v-if="showDoneActionPopup"
      :card="doneActionSelected"
      @close="showDoneActionPopup = false"
    />
  </div>
</template>

<script>
import CreateActionPopup from "../../../components/Actions/CreateActionPopup.vue";
import Button from "@components/shared/button/Button.vue";
import VueCal from "vue-cal";
import ActionOutcomePopup from "../../../components/Actions/ActionOutcomePopup.vue";
import DoneActionPopup from "../../../components/Actions/DoneActionPopup.vue";


export default {
  components: {
    CreateActionPopup,
    ActionOutcomePopup,
    DoneActionPopup, 
    "lila-button": Button,
    "vue-cal": VueCal
  },

  data() {
    return {
      showNewActionPopup: false,
      showOutcomePopup: false,
      showDoneActionPopup: false,
      doneActionSelected: null,
      pendingActions: [],
      doneActions: [],
      draggedCard: null,
      professionals: [],
      professionalsDropdownVisibleForCard: [],
      calendarDropdownVisibleForCard: [],
      alert: {
        message: "",
        type: "",
        show: false
      },
      outcomeOptions: [],
      loading: false
    };
  },
  computed: {
    mappedOutcomeOptions() {
      return this.outcomeOptions.map((option) => ({
        _id: option.Id,
        name: option.Name
      }));
    }
  },
  created() {
    this.loadActions();
    this.loadProfessionals();
    this.loadOutcomeTypes();
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    async loadActions() {

      this.loading = true;

      await this.$HTTPUtilities.get(
        this,
        `/api/action/patient/${this.$route.params.id}`,
        (res) => {
          res.data.forEach((action) => {
            switch (action.Status.toLowerCase()) {
              case "pendente":
                this.pendingActions.push({
                  Id: action.Id,
                  title: action.ActionType.Name,
                  color: this.chooseCategoryColor(action.ActionType),
                  dueDate: action.DueDate,
                  assignedProfessional: {
                    Id: action.ProfessionalAssigned?.Id,
                    Name: action.ProfessionalAssigned?.Name,
                    Photo: action.ProfessionalAssigned?.Photo
                  },
                  status: 0
                });
                break;
              case "concluido":
                this.doneActions.push({
                  Id: action.Id,
                  title: action.ActionType.Name,
                  color: this.chooseCategoryColor(action.ActionType),
                  dueDate: action.DueDate,
                  actionOutcomeObservations: action.ActionOutcomeObservations,
                  assignedProfessional: {
                    Id: action.ProfessionalAssigned?.Id,
                    Name: action.ProfessionalAssigned?.Name,
                    Photo: action.ProfessionalAssigned?.Photo
                  },
                  conclusionDate: action.ConclusionDateAndTime,
                  status: 1
                });
                break;
            }
          });
          this.loading = false;

        },
        this.alert
      );
    },
    async loadOutcomeTypes() {
      await this.$HTTPUtilities.get(
        this,
        "/api/action/outcomes",
        (res) => {
          res.data.ActionOutcomes.forEach((outcome) => {
            this.outcomeOptions.push(outcome);
          });
        },
        this.alert
      );
    },
    async loadProfessionals() {
      await this.$HTTPUtilities.get(
        this,
        "/api/professional",
        (res) => {
          res.data.forEach((professional) => {
            this.professionals.push({
              Id: professional.Id,
              Name: professional.Name,
              Photo: professional.Photo
            });
          });
        },
        this.alert
      );
    },
    async updateAction(card) {
      await this.$HTTPUtilities.put(
        this,
        `/api/action/${card.Id}/patient/${this.$route.params.id}`,
        this.mountUpdateActionJSON(card),
        () => { },
        this.alert
      );
    },
    mountUpdateActionJSON(card) {
      return { ProfessionalAssignedId: card.assignedProfessional.Id, DueDate: card.dueDate, Status: card.status };
    },

    onDragStart(card, sourceColumn) {
      this.draggedCard = card;
      this.sourceColumn = sourceColumn;
    },
    onDrop(targetColumn) {

      if(this.sourceColumn == targetColumn) return;
      if (this.sourceColumn && this.draggedCard) {
        if (targetColumn === "doneActions") {
          this.showOutcomePopup = true;
        } else {
          this.changeCardColumn(targetColumn);
          this.draggedCard.status = 0;
          this.updateAction(this.draggedCard);
          this.draggedCard = null;
          this.sourceColumn = null;
        }
      }
    },
    changeCardColumn(targetColumn) {
      this[this.sourceColumn] = this[this.sourceColumn].filter(
        (card) => card !== this.draggedCard);
      this[targetColumn].push(this.draggedCard);

    },
    toggleProfessionalsDropdownInCard(cardId, index) {
      this.professionalsDropdownVisibleForCard.fill(false);
      this.professionalsDropdownVisibleForCard[cardId] = true;
      this.scrollToCardIfNotVisible(index);
    },

    toggleCalendarDropdownInCard(cardId, index) {
      this.calendarDropdownVisibleForCard.fill(false);
      this.calendarDropdownVisibleForCard[cardId] = true;
      this.scrollToCardIfNotVisible(index);
    },

    selectProfessionalForCard(card, professional) {
      card.assignedProfessional = professional;
      this.updateAction(card);
      this.professionalsDropdownVisibleForCard[card.Id] = false; // Feche o dropdown
    },

    selectDueDateForCard(date, card) {
      card.dueDate = date.format("YYYY-MM-DD");
      this.updateAction(card);
      this.calendarDropdownVisibleForCard[card.Id] = false; // Feche o dropdown
    },

    chooseCategoryColor(actionType) {
      const hue = (actionType.Id * 137.508) % 360;
      const saturation = 60 + ((actionType.Id * 29) % 20);
      const lightness = 70 + ((actionType.Id * 23) % 10);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    handleClickOutside(event) {
      this.closeAssignedProfessionalDropdownIfOpen(event);
      this.closeCalendarDropdownIfOpen(event);
    },

    closeAssignedProfessionalDropdownIfOpen(event) {
      const professionalsListDropdown = this.$el.querySelector(".professionals-assigned-dropdown-list-on-card");
      if (professionalsListDropdown && !professionalsListDropdown.contains(event.target))
        this.professionalsDropdownVisibleForCard.fill(false);
    },

    closeCalendarDropdownIfOpen(event) {
      const calendarDropdown = this.$el.querySelector(".vuecal--date-picker");
      if (calendarDropdown && !calendarDropdown.contains(event.target))
        this.calendarDropdownVisibleForCard.fill(false);
    },

    async scrollToCardIfNotVisible(index) {
      const cardElement = this.$refs.kanbanCard[index]; // O card que estamos verificando
      const columnElement = cardElement.closest(".kanban-column"); // A classe do seu contêiner

      await this.$nextTick();

      if (cardElement.offsetTop > (columnElement.scrollTop + columnElement.clientHeight))
        cardElement.scrollIntoView({ behavior: "smooth", block: "start" });

    },
    openClosedActionDetails(card) {
      this.doneActionSelected = card;
      this.showDoneActionPopup = true;
    }
  }
};
</script>

<style scoped> 
@import "~@assets/css/views/lilaCalendar.css";

.kanban {
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.kanban-column {
  flex: 1;
  margin: 0 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: 500px;
  overflow-y: auto;
}

.kanban-pending-header,
.kanban-done-header {
  background-color: #69287c;
  color: white;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.kanban .card {
  background-color: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 2px solid;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kanban .kanban-card-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.kanban .kanban-card-content-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.kanban .card h3 {
  margin-top: 0;
  color: #333;
  font-size: 14px;
}

.kanban .card ul {
  list-style-type: none;
  padding: 0;
  font-size: 14px;
}

.kanban .card ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.kanban .professionals-assigned-dropdown-list-on-card {
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #ddd;
  width: 210px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 10000;
  top: 100%;
  left: 35px;
}

.kanban ul li:hover {
  text-decoration: underline;
}

.kanban ul li {
  margin-bottom: 10px;
  cursor: pointer;
  color: #6a1b9a;
}

.kanban .calendar-position {
  position: absolute;
  z-index: 10000;
  top: 100%;
  right: 0%;
}

#action-due-date,
#assigned-professional-name {
  font-size: 14px;
  cursor: pointer;
}

#update-due-date-calendar {
  color: #4a4a4a;
  background-color: white;
  position:block;
}

@media (max-width: 1214px) {
  .kanban {
    padding: 15px;
  }

  .kanban-column {
    margin: 0 5px;
  }

  .kanban .card {
    height: auto;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .kanban .kanban-card-content-bottom {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  #action-due-date,
  #assigned-professional-name {
    font-size: 13px;
  }

  .kanban .professionals-assigned-dropdown-list-on-card {
    width: 180px;
    left: 0;
  }

  .kanban .card ul li {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .kanban-pending-header,
  .kanban-done-header {
    font-size: 18px;
  }

  .kanban .card h3 {
    font-size: 13px;
  }


  .kanban .calendar-position {
    position: absolute;
    z-index: 10000;
    top: 100%;
    left: 0px;
  }
}


@media (max-width: 840px) {
  .kanban {
    flex-direction: column;
    padding: 10px;    
  }

  .kanban-column {
    margin: 10px 0;
    width: 100%;
    height: auto;
  }

  .kanban .card {
    padding: 10px 5px;
  }

  .kanban .kanban-card-content {
    height: auto;
    display: block;
  }

  
  .kanban .kanban-card-content-bottom {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .kanban .card h3 {
    font-size: 12px;
  }

  #action-due-date,
  #assigned-professional-name {
    font-size: 12px;
  }

  .kanban .professionals-assigned-dropdown-list-on-card {
    width: 100%;
    left: 0;

  }

  .kanban-pending-header,
  .kanban-done-header {
    font-size: 16px;
  }

}

</style>
