<template>
  <div class="field checkbox-component">
    <div
      class="control"
      :class="checkboxAlignment"
    >
      <label class="checkbox-container model-1">
        <span
          class="checkbox-title"
          v-html="title"
        />
        <input
          v-model="checked"
          type="checkbox"
          :value="val"
          @change="changeEvent"
          @click="clickEvent"
        >
        <span class="checkmark" :class="{preCheckmark : preCheck}"/>
      </label>

      <span
        v-if="validation.$error"
        class="validation-error"
      >{{ $Utilities.getErrorMsg(validation) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    val: {
      required: false
    },
    modelValue: {

    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    preCheck: {
      required: false,
      type: Boolean,
      default: false
    },
    isCentralized: {
      required: false,
      type: Boolean,
      default: false
    },
    validation: {
      required: false,
      type: Object,
      default: () => {
        return {
          $error: false
        };
      }
    }
  },
  emits: ['update:modelValue', 'customChangeEvent', 'customClickEvent'],
  data () {
    return {
      checkedItem: false
    };
  },
  computed: {
    checked: {
      get () {
        return this.modelValue;
      },
      set (val) {
        this.checkedItem = val;
      }
    },
    checkboxAlignment(){
      let alignmentClass = "";

      if(this.isCentralized) return "has-text-centered";

      switch (this.alignment) {
      case "left":
        alignmentClass = "has-text-left";
        break;

      case "right":
        alignmentClass = "has-text-right";
        break;

      case "centered":
        alignmentClass = "has-text-centered";
        break;

      case "justified":
        alignmentClass = "has-text-justified";
        break;
      }

      return alignmentClass;
    }
  },
  methods: {
    changeEvent($event){
      if(typeof this.checkedItem == "boolean"){
        this.$emit("update:modelValue", $event.target.checked);
        this.$emit("customChangeEvent", $event.target.checked);
      }else{
        this.$emit("update:modelValue", this.checkedItem);
        this.$emit("customChangeEvent");
      }
    },
    clickEvent(){
      this.$emit("customClickEvent");
    }
  }
};
</script>

<style>
    @import '~@assets/css/components/checkbox.css';
</style>
