import jwtDecode from "jwt-decode";
import { userSessionName } from "../js/axios";
import { Session } from "./Session";
import { Patient, PatientCID, Alert, Field, FrontendPatient } from "../models/index";
import { Estadio, PerformanceStatus, AlertMessage, AlertType, performanceStatusList } from '../enums/Index';
import { DecodedToken } from "../models/decodedToken";


import moment from "moment";
import axios, { AxiosError } from "axios"; // Import axios and AxiosError
import router from "../router";


// Define the Utilities class in TypeScript
class UtilitiesV2 {

  isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
  }

  decodeToken(token: string): DecodedToken | null {
    try {
      return jwtDecode<DecodedToken>(token);  // Specify that the decoded token follows the DecodedToken interface
    } catch (error) {
      console.error("Error decoding token", error);
      return null;
    }
  }

  formatDate(date: string | null, inputFormat = "DD/MM/YYYY", outputFormat = "YYYY-MM-DD"): string {
    // Check if date is null or an empty string
    if (!date) {
      return ""; // Return empty string or some default value
    }

    // Proceed with formatting if date is valid
    return moment(date, inputFormat).format(outputFormat);
  }


  formatDateWithTime(date: string | null | undefined, inputFormat = "YYYY-MM-DDTHH:mm:ss", outputFormat = "DD/MM/YYYY"): string {
    // Check if the date is null or undefined
    if (!date) {
      return ""; // Return an empty string or any default value you prefer
    }

    // Remove 'Z' from the date if present
    date = date.replace(/Z/, "");

    // Format the date using moment.js
    return moment(date, inputFormat).format(outputFormat);
  }


  getErrorMsg(field: Field): string | undefined {
    if (field.required) return "Campo obrigatório.";
    if (field.sameAsPassword) return "As senhas não são iguais.";
    if (field.email) return "Informe um email válido.";
    if (field.url) return "Informe uma URL válida.";
    if (field.maxLength) return `O campo pode conter no máximo ${field.maxLength.max} caracteres.`;
    if (field.minLength) return `O campo deve conter no mínimo ${field.minLength.min} caracteres.`;
    if (field.validCpf) return "Informe um CPF válido.";
    if (field.validCns) return "O CNS deve conter exatamente 15 dígitos numéricos.";
    if (field.validPhone) return "Informe um telefone válido.";
    if (field.validFormatImage) return "Utilize apenas imagens nos formatos JPEG ou PNG.";
    if (field.checkSpaces) return "A senha não pode conter espaços.";
    if (field.numeric) return "Apenas números são permitidos.";
    if (field.advisorRequired) return "É necessário informar um assessor.";
    if (field.validateDate) return "Informe um horário válido.";
    if (field.verifyLastName) return "Deve ser inserido pelo menos um sobrenome.";
    if (field.checkNumber) return "A senha deve conter pelo menos um número.";
    if (field.checkUpperCase) return "A senha deve conter pelo menos uma letra maiúscula.";
    if (field.terms) return "É necessário aceitar os Termos de Uso.";
    if (field.verifyMedicalCareLocation) return "Pelo menos um local de atendimento deve ser informado.";
    if (field.dateLessOrEqualToCurrent) return "A data não pode ser superior a data atual.";
    if (field.endDateMustBeGreater) return "A data fim deve ser maior que a data início.";
  }

  validateCPF(strCpf: string): boolean {
    let Soma = 0;
    let Resto;
    let i = 1;

    strCpf = strCpf.replace(/\D/g, "");

    if (strCpf === "00000000000") return false;

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCpf.substring(i - 1, i)) * (11 - i);

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto != parseInt(strCpf.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCpf.substring(i - 1, i)) * (12 - i);

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto != parseInt(strCpf.substring(10, 11))) return false;

    return true;
  }

  async buscarEndereco(endereco: Patient, loading: any): Promise<void> {
    if (!endereco.CEP) return;
    const rawCep = endereco.CEP.replace(/\D/g, "");
  
    if (rawCep.length < 8) return;

    loading.loadingCep = true;

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${rawCep}/json/`);
      if (response.data.erro) return;

      endereco.Street = response.data.logradouro;
      endereco.Complement = response.data.complemento;
      endereco.Neighborhood = response.data.bairro;
      endereco.City = response.data.localidade;
      endereco.UF = response.data.uf;
    } finally {
      loading.loadingCep = false;
    }
  }

  typePhone(phone: string): string | boolean {
    if (phone.length === 10 || phone.length === 11) {
      const expFixo = /^([1-9][1-9])?[\s-]?[2-7]\d{3}-?\d{4}$/;
      const expMovel = /^([1-9][1-9])?[\s-]?9[2-9]\d{3}-?\d{4}$/;

      if (expFixo.test(phone)) return "Fixo";
      if (expMovel.test(phone)) return "Celular";
    }

    return false;
  }

  removeAccent(string: string): string {
    return string
      .toLowerCase()
      .trim()
      .replace(new RegExp("[àáâãäå]", "g"), "a")
      .replace(new RegExp("ç", "g"), "c")
      .replace(new RegExp("[èéêë]", "g"), "e")
      .replace(new RegExp("[ìíîï]", "g"), "i")
      .replace(new RegExp("ñ", "g"), "n")
      .replace(new RegExp("[òóôõö]", "g"), "o")
      .replace(new RegExp("[ùúûü]", "g"), "u")
      .replace(new RegExp("[ýÿ]", "g"), "y");
  }

  controlAlert(
    res: any,
    alert: Alert,
    defaultMessage = ""
  ): void {
    alert.message = alert.message || defaultMessage || AlertMessage.ERROR;
    //alert.type = alert.type; // Preciso dessa linha mesmo?
    alert.show = true;
    window.scrollTo(0, 0);
  }

  showResultMessage(params: any, alert: Alert): void {
    if (this.hasProperty(params, "message")) {

      alert.message = params.message;
      this.controlAlert(null, alert);
    } else if (this.hasProperty(params, "customMessage")) {
      alert.message = params.customMessage.message;
      alert.type = params.customMessage.type;
      this.controlAlert(null, alert);
    }
  }

  hasProperty(obj: any, propertyPath: string): boolean {
    if (!propertyPath) return false;

    let properties = propertyPath.split(".");
    for (let i = 0; i < properties.length; i++) {
      let prop = properties[i];
      if (!obj || !obj.hasOwnProperty(prop)) {
        return false;
      } else {
        obj = obj[prop];
      }
    }
    return true;
  }

  formatMoney(money: number): string {
    return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(money);
  }

  checkFullRegistration(): void {
    const session = Session.get(userSessionName);
    if (
      session?.doctor?.CPF &&
      session?.doctor?.BirthDate &&
      session?.doctor?.Gender &&
      session?.doctor?.Addresses[0]
    ) {
      router.push({ name: "patientTickets" });
    }
  }

  getFileExtension(fileName: string): string {
    return fileName.split(".").pop() || "";
  }

  getFileName(filePath: string): string {
    return filePath.split("/").pop() || "";
  }

  verifyToken(status: number): void {
    if (status === 401) {
      Session.remove(userSessionName);
      router.push({ name: "login", params: { message: "sessionExpired" } });
    }
  }

  leadingZero(number: number): string {
    return number < 10 ? `0${number}` : `${number}`;
  }

  addHiperlinkinText(text: string): string {
    const urlList = text.match(/\bhttps?:\/\/\S+/gi);
    if (urlList != null) {
      urlList.forEach((url) => {
        text = text.replace(url, `<a class="hiperlink" href="${url}" target="_blank">${url}</a>`);
      });
    }
    return text;
  }

  getValueIgnoreCase(object: any, key: string): any {
    if (object == null) {
      return undefined;
    }

    let keysLowerCase = Object.keys(object).map((keyName) => keyName.toLowerCase());
    const index = keysLowerCase.indexOf(key.toLowerCase());
    return index !== -1 ? Object.values(object)[index] : undefined;
  }

  getPatientCid(patient: Patient): string | undefined {
    // Function to safely retrieve a value ignoring the case of the key
    const getValueIgnoreCase = (obj: any, key: string) => {
      if (!obj || typeof obj !== 'object') return undefined;
      const foundKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
      return foundKey ? obj[foundKey] : undefined;
    };

    const CIDs = getValueIgnoreCase(patient, "CIDs") as PatientCID[] | undefined;

    const activeCidList: PatientCID[] = CIDs?.filter(item => getValueIgnoreCase(item, "IsEnable")) ?? [];

    const firstActiveCid = activeCidList[0];

    // Handle case where no active CIDs are present and CurrentPS is used
    if (activeCidList.length === 0 && getValueIgnoreCase(patient, "CurrentPS")) {
      return this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"));
    }

    // Handle single active CID
    if (activeCidList.length === 1 && firstActiveCid) {
      const CIDCode = getValueIgnoreCase(firstActiveCid.CID, "CIDCode");
      const Estadio = getValueIgnoreCase(firstActiveCid, "Estadio");
      return `${CIDCode} . ${this.getPatientEstadio(Estadio)}${getValueIgnoreCase(patient, "CurrentPS") ? `. ${this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"))}` : ""}`;
    }

    // Handle multiple active CIDs
    if (activeCidList.length > 1) {
      return `C97${getValueIgnoreCase(patient, "CurrentPS") ? `. ${this.getPatientPs(getValueIgnoreCase(patient, "CurrentPS"))}` : ""}`;
    }

    return undefined;
  }

  intensityPainName(intensity: number): string {
    const intensityList = ["", "Leve", "Moderado", "Grave", "Muito Grave"];
    return intensityList[intensity] !== undefined ? intensityList[intensity] : "";
  }

  getDefaultProfilePicture(genre: string): string | undefined {
    switch (genre) {
      case "Feminino":
        return require(`@components/shared/lila/profilePicturePlaceholder/${Math.floor(Math.random() * 4) + 1}.png`);
      case "Masculino":
        return require("@components/shared/lila/profilePicturePlaceholder/man_1.png");
      default:
        return undefined;
    }
  }

  inRange(minValue: number | null, maxValue: number | null, value: number): boolean {
    if (minValue === null && maxValue !== null) return value <= maxValue;
    if (maxValue === null && minValue !== null) return value >= minValue;
    if (minValue === null && maxValue === null) return true; // Both are null, so no range restriction
    return value >= (minValue as number) && value <= (maxValue as number);
  }


  getAge(date: string): string {
    return moment(date, "MM/DD/YYYY")
      .fromNow()
      .replace(" years ago", "")
      .replace("a year ago", "1") + "a";
  }

  getAbbrevGender(gender: string): string {
    switch (gender) {
      case "Masculino":
        return "Masc";
      case "Feminino":
        return "Fem";
      default:
        return gender;
    }
  }

  scrollToInvalidField(): void {
    const errorElement = document.querySelector(".error");
    if (errorElement) {
      // Get the element's top position relative to the document
      const elementPosition = errorElement.getBoundingClientRect().top + window.scrollY;

      // Get the viewport height and calculate the offset to center the element
      const viewportHeight = window.innerHeight;
      const offsetTop = elementPosition - viewportHeight / 2;

      // Scroll the page smoothly to the calculated position
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }

  hasAccessToFeature(featureId: number) {
    const decodedToken = this.decodeToken(Session.get(userSessionName).token.Value);
    return decodedToken && decodedToken.FeaturesId.includes(featureId);
  }

  
    /**
     * Método getSelectedPatientCid
     *
     * Este método é responsável por obter o CID ativo (Classificação Internacional de Doenças) do paciente.
     * Ele verifica se o paciente possui CIDs ativos (onde a propriedade IsEnable é verdadeira) e retorna
     * o CID e o respectivo Estádio (fase da doença) no formato correto para exibição.
     *
     * - Se não houver CIDs ativos, o método retorna o PS (Performance Status) do paciente.
     * - Se houver apenas um CID ativo, o método retorna o código do CID, o estádio da doença, e o PS do paciente.
     * - Se houver mais de um CID ativo, retorna "C97" seguido do PS do paciente, que é um código genérico para múltiplos CIDs.
     *
     * Isso garante que, mesmo quando o paciente não tiver CIDs ativos, o PS seja exibido.
     */
    getSelectedPatientCid(patient: Patient) {
      let activeCidList =
        patient.CIDs && patient.CIDs[0] != null
          ? patient.CIDs.filter((item) => item.IsEnable)
          : [];

      if (activeCidList.length === 0) {
        return this.getPatientPs(patient.PS); // Use PS from patient if no CIDs are active
      }
      if (activeCidList.length === 1) {
        return `${activeCidList[0].CID.CIDCode} . ${this.getPatientEstadio(
          activeCidList[0].Estadio
        )} . ${this.getPatientPs(patient.PS)}`;
      }
      if (activeCidList.length > 1) {
        return `C97 . ${this.getPatientPs(patient.PS)}`;
      }
    }

    getPatientEstadio(estadio: string) {
      switch (estadio) {
        case "Localizado":
          return "Loc";
        case "Extenso":
          return "Ext";
        case "Não se aplica":
          return "NA";
        default:
          return estadio; // Default to raw value if it doesn't match known values
      }
    }

    getPatientPs(ps: number | null | undefined) {
      const selectedPs = performanceStatusList.find((item) => item._id === ps);

      return selectedPs ? selectedPs.name : "";
    }

}

// Export an instance of Utilities to use throughout the app
export const utilities = new UtilitiesV2();

// Main function to provide Utilities globally in Vue
export default function (app: any): void {
  app.config.globalProperties.$UtilitiesV2 = utilities;
}
