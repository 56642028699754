<template>
  <div
    v-if="dialogs.length"
    class="lila-dialog-wrapper"
  >
    <div
      v-for="(item, index) in dialogs"
      :key="`lila_dialog_${index}`"
      class="lila-dialog-container"
      @click="item.close()"
    >
      <component
        :is="item.component"
        v-bind="item.componentProps"
      />
    </div>
  </div>
</template>

<script setup>
import useDialog from "@composables/useDialog";

const {
    dialogs
} = useDialog();
</script>

<style>
.lila-dialog-wrapper {
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
}

.lila-dialog-wrapper .lila-dialog-container {
  height: 100%;
  display: flex;
  cursor: 'pointer'
}

</style>
