export default function(app) {
  app.config.globalProperties.$AccessControl = {
    cannot: (profile, action) => {
      switch (profile) {
      case "professional_leader":
        return [
          "export_patient_data"].indexOf(action.toLowerCase()) != -1;

      case "professional_default":
        return [
          "announcements_page",
          "export_patient_data",
          "add_patient",
          "edit_medical_record"].indexOf(action.toLowerCase()) != -1;

      case "doctor":
        return [].indexOf(action.toLowerCase()) != -1;
      }
    }
  };
}
