<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Complete seu Cadastro
        </h1>

        <lila-message :alert-data="alert" />

        <div class="columns">
          <div class="column is-5">
            <p>Nos ajude a te conhecer melhor.</p>

            <div class="field-group">
              <div class="field-group-header align-title">
                <p>Dados</p>
              </div>

              <div class="field-group-body">
                <lila-datepicker
                  v-model="v.data.birthDate.$model"
                  placeholder="Data de Nascimento"
                  :validation="v.data.birthDate"
                />

                <lila-select
                  v-model="v.data.gender.$model"
                  placeholder="Sexo"
                  :options="genderList"
                  :validation="v.data.gender"
                />

                <lila-mask
                  v-model="v.data.cpf.$model"
                  placeholder="CPF"
                  mask="###.###.###-##"
                  :validation="v.data.cpf"
                />
              </div>
            </div>
          </div>

          <div class="column is-2 middle-column">
            <div class="line" />
          </div>

          <div class="column is-5">
            <p class="has-text-centered">
              Sua Foto
            </p>

            <div class="icon-upload">
              <lila-svg-icon
                v-show="!file.uploaded"
                class="upload-placeholder is-cursor-pointer"
                :src="require(`@assets/icons/your_photo.svg`)"
                @custom-click-event="openFolderDirectory"
              />

              <figure
                v-show="file.uploaded"
                class="image"
              >
                <img
                  id="imagePreview"
                  ref="imagePreview"
                  src="#"
                  class="is-rounded"
                >
              </figure>

              <input
                id="fileInput"
                ref="file"
                type="file"
                class="file-input"
                @change="handleFileUpload"
              >
            </div>

            <button
              v-show="file.uploaded"
              type="button"
              class="remove-image"
              @click="deleteImage"
            >
              <lila-svg-icon :src="require(`@assets/icons/delete.svg`)" /> Excluir
            </button>
          </div>
        </div>

        <lila-button
          type="submit"
          title="Salvar"
          class="submit-buttons"
          button-style="edge-button"
          :second-button="{type: 'routeText', route: 'registrationData', title: 'Continuar mais tarde', class: 'dark-lilas'}"
          :loading="loading"
        />
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import LilaDatepicker from "@components/shared/fields/LilaDatepicker.vue";
import LilaMask from "@components/shared/fields/LilaMask.vue";
import Select from "@components/shared/fields/Select.vue";
import Button from "@components/shared/button/Button.vue";
import SvgIcon from "@components/shared/images/SvgIcon.vue";
import Message from "@components/shared/message/Message.vue";

import { required } from "@vuelidate/validators";

import GenderOptions from "@assets/options/gender.json";

const validCpf = function(value){
  return this.$Utilities.validateCPF(value);
};

export default {
  components: {
    "lila-select": Select,
    "lila-button": Button,
    "lila-svg-icon": SvgIcon,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate,
    LilaMask,
    LilaDatepicker
  },
  data() {
    return {
      data: {
        crm: this.$Session.get(this.$userSessionName).doctor.CRM,
        ufcrm: this.$Session.get(this.$userSessionName).doctor.UFCRM,
        name: this.$Session.get(this.$userSessionName).doctor.Name,
        email: this.$Session.get(this.$userSessionName).doctor.Email,
        phone: this.$Session.get(this.$userSessionName).doctor.Phone,
        birthDate: this.$Session.get(this.$userSessionName).doctor.BirthDate ? this.$Session.get(this.$userSessionName).doctor.BirthDate : "",
        gender: this.$Session.get(this.$userSessionName).doctor.Gender ? this.$Session.get(this.$userSessionName).doctor.Gender : "",
        cpf: this.$Session.get(this.$userSessionName).doctor.CPF ? this.$Session.get(this.$userSessionName).doctor.CPF : ""
      },
      file: {
        title: "",
        content: "",
        uploaded: false
      },
      genderList: GenderOptions.options,
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        birthDate: {required},
        gender: {required},
        cpf: {
          required,
          validCpf
        }
      }
    };
  },
  created(){
    this.$Utilities.checkFullRegistration();

    if(this.$Utilities.hasProperty(this.$route.params, "message")){
      this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
    }
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      Promise
        .all([
          this.saveComplementaryInformation(),
          this.uploadImage()
        ])
        .then(() => this.$router.push({name: "registrationData", params: {message: "basicInformation"}}))
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    },
    saveComplementaryInformation(){
      let doctor = Object.assign({}, this.data);

      doctor.birthDate = this.$Utilities.formatDate(doctor.birthDate, "DD/MM/YYYY", "MM/DD/YYYY");

      return this.$axios({
        method: "PUT",
        url: "api/doctor",
        data: doctor,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.$Session.setUserAttribute("BirthDate", this.data.birthDate);
          this.$Session.setUserAttribute("Gender", this.data.gender);
          this.$Session.setUserAttribute("CPF", this.data.cpf);
        });
    },
    uploadImage(){
      if(!this.file.uploaded) return;

      let formData = new FormData();

      formData.append("photo", this.file.content);

      return this.$axios({
        method: "PUT",
        url: "/api/doctor/upload",
        data: formData,
        headers: {
          "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => this.$Session.setUserAttribute("Photo", res.data.photo));
    },
    openFolderDirectory(){
      this.$refs.file.click();
    },
    handleFileUpload(){
      let reader = new FileReader();

      this.file.content = this.$refs.file.files[0];
      this.file.title = this.file.content.name;

      reader.onload = (e) => {
        document.querySelector("#imagePreview").src = e.target.result;

        this.file.uploaded = true;
      };

      reader.readAsDataURL(this.file.content);
    },
    deleteImage(){
      document.querySelector("#imagePreview").src = "";

      this.$refs.file.value = "";

      this.file.title = "";
      this.file.content = "";
      this.file.uploaded = false;
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/complementaryRegistration.css';
</style>
