import jwtDecode from "jwt-decode";

export default function decodeToken(token) {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error("Error decoding token", error);
        return null;
    }
}
