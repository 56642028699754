<template>
  <lila-default-template>
    <template #mainContent>
      <div
        class="container announcements"
        :class="loadingContent ? 'loading large' : ''"
      >
        <h1 class="title">
          Comunique seus Pacientes
        </h1>

        <form
          v-show="!loadingContent"
          class="basic-info-form"
          @submit.prevent="submitForm()"
        >
          <lila-message :alert-data="alert" />

          <div class="columns">
            <div class="column is-5">
              <div class="field-group">
                <div class="field-group-header align-title">
                  <p>Espaço para comunicações de interesse geral</p>
                </div>

                <div class="field-group-body">
                  <lila-input
                    v-model="v.data.title.$model"
                    placeholder="Chamada"
                    :validation="v.data.title"
                  />

                  <lila-textarea
                    v-model="v.data.message.$model"
                    placeholder="Escreva seu comunicado"
                    :validation="v.data.message"
                  />

                  <div class="address-list">
                    <div class="field-group-header align-title">
                      <p>Selecione o grupo de pacientes</p>
                    </div>

                    <ul>
                      <li
                        v-for="team in teams"
                        :key="`team_${team._id}`"
                      >
                        <lila-checkbox
                          v-model="data.teamsList"
                          :title="team.Name"
                          :val="team.Id"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <lila-button
                type="submit"
                title="Enviar"
                class="submit-buttons"
                :loading="loading"
              />
            </div>

            <div class="column is-2 middle-column">
              <div class="line" />
            </div>

            <div class="column is-5">
              <div class="field-group">
                <div class="field-group-header">
                  <p>Lista de Comunicados Enviados</p>
                </div>

                <div class="field-group-body">
                  <div class="announcements-history">
                    <div
                      v-for="item in data.announcementsHistory"
                      :key="item.id"
                      class="history-item"
                    >
                      <p>
                        <time>{{ item.date }}</time>
                        <span>
                          <span
                            v-if="item.title"
                            class="has-text-weight-medium"
                          >{{ item.title }}</span>
                          <br v-if="item.title"> {{ item.message }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Textarea from "@components/shared/fields/Textarea.vue";
import Checkbox from "@components/shared/fields/Checkbox.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required } from "@vuelidate/validators";

export default {
  components: {
    "lila-input": Input,
    "lila-textarea": Textarea,
    "lila-checkbox": Checkbox,
    "lila-button": Button,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate
  },
  data() {
    return {
      data: {
        title: "",
        message: "",
        announcementsHistory: [],
        teamsList: []
      },
      teams: [],
      loading: false,
      loadingContent: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        title: {required},
        message: {required}
      }
    };
  },
  created(){
    this.getAnnouncementsList();
  },
  methods: {
    getAnnouncementsList(){
      this.alert.show = false;
      this.loadingContent = true;

      this.$axios
        .get(
          "/api/announcement",
          {headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}}
        )
        .then((res) => {
          this.teams = this.$Session.get(this.$userSessionName).professional.Teams;
          this.data.announcementsHistory = res.data.map((item, index) => {
            return {
              id: index + 1,
              date: this.$Utilities.formatDateWithTime(item.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY - HH:mm:ss"),
              title: item.Title,
              message: item.Message
            };
          });
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loadingContent = false);
    },
    submitForm(){
      this.alert.show = false;

      this.v.$touch(); // forçando a validar o fomulário

      if(this.v.$invalid) return;

      if(this.data.teamsList.length == 0){
        this.$Utilities.controlAlert(null, this.alert, "danger", "É necessario informar um grupo de pacientes.");
        return;
      }

      let formData = new FormData();

      formData.append("Title", this.data.title);
      formData.append("Message", this.data.message);

      for(let index in this.data.teamsList){
        formData.append(`Teams[${index}]`, this.data.teamsList[index]);
      }

      this.loading = true;

      this.$axios({
        method: "POST",
        url: "api/announcement",
        data: formData,
        headers: {
          "Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          this.data.announcementsHistory.unshift({
            id: this.data.announcementsHistory.length + 1,
            date: this.$Utilities.formatDateWithTime(res.data.DateAndTime, "YYYY-MM-DDTHH:mm:ss", "DD/MM/YYYY - HH:mm:ss"),
            title: res.data.Title,
            message: res.data.Message
          });

          this.data.title = "";
          this.data.message = "";
          this.data.teamsList = [];

          this.v.$reset();

          this.$Utilities.controlAlert(null, this.alert, "success", "Comunicado enviado com sucesso.");
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/announcements.css';
</style>
