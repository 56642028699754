<template>
  <div class="patient-card patient-card-component">
    <div
      v-for="patient in dataList"
      :key="patient.Id"
      class="card"
      :class="
        selectedPatient && selectedPatient.Id == patient.Id ? 'active' : ''
      "
      @click="clickEvent(patient)"
    >
      <router-link :to="{ name: routerPath, params: { id: patient.Id } }">
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <div class="is-profile-picture-64x64">
                  <img
                    :src="getProfilePicture(patient.profilePicture)"
                    alt="Foto do paciente"
                  >
                </div>
              </figure>
            </div>

            <div class="media-content">
              <div class="content">
                <div class="content-line">
                  <div class="patient">
                    {{ patient.Name }}
                  </div>

                  <div class="cid">
                    {{ patient.displayCid }}
                  </div>
                </div>

                <div class="detail">
                  {{ $Utilities.getAbbrevGender(patient.Gender)
                  }}{{
                    patient.BirthDate
                      ? `, ${$Utilities.getAge(patient.BirthDate)}`
                      : ""
                  }}
                </div>

                <div
                  v-if="patient.Diagnostic"
                  class="diagnostic"
                >
                  {{ messageSlice(patient.Diagnostic) }}
                </div>

                <div
                  v-if="patient.painLevel"
                  class="painLevel"
                >
                  Dor: <strong>{{ patient.painLevel }}</strong>
                </div>

                <div class="serviceLocation">
                  <strong>{{ patient.doctor }}</strong>
                </div>
              </div>
            </div>
          </article>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      required: true,
      type: Array,
      default: () => {
        return [
          {
            Id: "", // Corresponds to id
            Name: "", // Corresponds to name
            profilePicture: "", // Corresponds to profilePicture
            displayCid: "", // Corresponds to the new displayCid
            Gender: "", // Corresponds to gender
            BirthDate: "", // Corresponds to birthDate
            Diagnostic: "", // Corresponds to diagnostic
            painLevel: "", // Frontend-specific painLevel
            serviceLocation: "", // Frontend-specific serviceLocation
            doctor: "", // Frontend-specific doctor
          },
        ];
      },
    },
    selectedPatient: {
      required: false,
      type: Object,
      default: null,
    },
    routerPath: {
      required: false,
      type: String,
      default: "",
    },
  },
  emits: ['customClickEvent'],
  methods: {
    clickEvent(patient) {
      this.$emit("customClickEvent", patient);
    },
    getProfilePicture(profilePicture) {
      return profilePicture != "" && profilePicture != null
        ? profilePicture
        : require(`@components/shared/lila/profilePicturePlaceholder/${
            Math.floor(Math.random() * 5) + 1
          }.png`);
    },
    messageSlice(message) {
      if (message?.length > 75) return `${message.substring(0, 75)}...`;

      return message;
    },
    created() {
      // This will log the props when the component is mounted
      console.log(
        "Props received:",
        this.dataList,
        this.selectedPatient,
        this.routerPath
      );

    },
  },
};
</script>

<style>
@import "~@assets/css/components/patientCard.css";
</style>
