<template>
  <lila-default-template>
    <template #mainContent>
      <form
        class="container basic-info-form"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Alterar Senha
        </h1>

        <lila-message :alert-data="alert" />

        <div class="columns">
          <div class="column is-5">
            <lila-input
              v-model="v.data.oldPassword.$model"
              placeholder="Senha Atual"
              type="password"
              :validation="v.data.oldPassword"
            />

            <lila-input
              v-model="v.data.newPassword.$model"
              placeholder="Digite a nova senha"
              type="password"
              :validation="v.data.newPassword"
            />

            <lila-input
              v-model="v.data.confirmPassword.$model"
              placeholder="Confirme a nova senha"
              type="password"
              :validation="v.data.confirmPassword"
            />

            <lila-button
              type="submit"
              title="Salvar"
              class="submit-buttons"
              :loading="loading"
            />
          </div>
        </div>
      </form>
    </template>
  </lila-default-template>
</template>

<script>
import DefaultTemplate from "@components/template/DefaultTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required, minLength, maxLength, sameAs } from "@vuelidate/validators";

const checkSpaces = function(value){
  return !/\s/.test(value);
};

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-default-template": DefaultTemplate
  },
  data() {
    return {
      data: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      loading: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        oldPassword: {
          required
        },
        newPassword: {
          required,
          checkSpaces,
          minLength: minLength(6),
          maxLength: maxLength(15)
        },
        confirmPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(15),
          sameAsPassword: sameAs(this.data.newPassword)
        }
      }
    };
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios({
        method: "PUT",
        url: "api/professional/password",
        data: this.data,
        headers: {"Authorization": `bearer ${this.$Session.get(this.$userSessionName).token.Value}`}
      })
        .then(() => {
          this.data.oldPassword = "";
          this.data.newPassword = "";
          this.data.confirmPassword = "";

          this.v.$reset();

          this.$Utilities.controlAlert(null, this.alert, "passwordChanged");
        })
        .catch(error => {
          this.$Utilities.verifyToken(error.response.status);
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger");
        })
        .finally(() => this.loading = false);
    }
  }
};
</script>

<style></style>
