<template>
  <div class="content terms-doc">
    <p>Os serviços do Lila são fornecidos pela Conexo Interfaces Dr. Carlos José Coelho de Andrade EIRELI, inscrito no CNPJ/CPF sob o nº 35918277/0001-82, titular da propriedade intelectual sobre software, website, aplicativos, conteúdos e demais ativos relacionados à plataforma Lila.</p>

    <h2>1. Do objeto</h2>                
    <p>A plataforma visa licenciar o uso de seu software, website, aplicativos e demais ativos de propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários.</p>
    <p>A plataforma caracteriza-se pela prestação de um canal de comunicação entre pacientes e suas equipes de saúde. A fase atual de desenvolvimento é a de investigação e avaliação de impacto do seu uso na forma de estudo clínico ou de acesso expandido na qual dados sarão coletados respeitando a Lei geral de proteção de dados e as boas práticas de investigação clínica. Os estudos clínicos em curso são aprovados pelos Comitês de Ética em Pesquisa (CEP) das Instituições envolvidas nas pesquisas.</p>

    <h2>2. Da aceitação</h2>
    <p>O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e os usuários do aplicativo.</p>            
    <p>Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a observá-las. Caso não concorde com os termos, o usuário não deve utilizá-lo.</p>

    <h2>3. Do acesso dos usuários</h2>
    <p>Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.</p>
        
    <h2>4. Do cadastro</h2>
    <p>O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio.</p>
    <p>O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros.</p>
    <p>Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.</p>
    <p>O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas comunicações necessárias e finalização do cadastro.</p>
    <p>Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.</p>
    <p>Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.</p>
    <p>Não será permitido ceder a conta, que é pessoal e intransferível.</p>
    <p>O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site www.lilasaude.com.br OU aplicativo lila. O cancelamento do seu cadastro será realizado o mais rapidamente possível.</p>

    <h2>5. Do suporte</h2>
    <p>Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através do email duvidas@lilasaude.com.br.</p>
    <p>Estes serviços de atendimento ao usuário estarão disponíveis de segunda a sexta feira de 9:00 às 17:00</p>

    <h2>6. Das responsabilidades</h2>
    <p>É de responsabilidade do usuário:</p>
    <ol class="is-lower-alpha">
      <li>a correta utilização da plataforma, dos serviços ou produtos oferecidos.</li>
      <li>pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade.</li>
      <li>pela proteção aos dados de acesso à sua conta/perfil (login e senha).</li>
    </ol>

    <p>É de responsabilidade da plataforma Lila:</p>
    <ol class="is-lower-alpha">
      <li>indicar as características do serviço ou produto;</li>
      <li>os defeitos e vícios encontrados no serviço ou produto oferecido desde que lhe tenha dado causa;</li>
    </ol>
    <p>Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.</p>

    <h2>7. Dos direitos autorais</h2>
    <p>O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos serviços e produtos por ela disponibilizados.</p>
    <p>A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da razão social Conexo Interfaces Dr. Carlos José Coelho de Andrade EIRELI, observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente reservados.</p>
    <p>Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora concedida.</p>
    <p>O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intellectual, puníveis nos termos da legislação aplicável.</p>
        
    <h2>8. Das alterações</h2>
    <p>
      Os itens descritos no presente instrumento poderão sofrer alterações para adequar ou modificar os serviços. As alterações serão veiculadas por e-mail ou pelo site <span v-if="termsApp">www.lilasaude.com.br</span> <a
        v-else
        href="http://www.lilasaude.com.br"
        target="_blank"
      >www.lilasaude.com.br</a>
    </p>
        
    <h2>9. Da política de privacidade</h2>
    <p>Esta política de privacidade rege o uso do Aplicativo Lila que foi criado para aprimorar a comunicação entre os pacientes e suas equipes de saúde.</p>
    <p>Todas as informações relativas aos usuários (equipes de saúde e pacientes) são confidenciais e criptografados de modo que não sejam utilizados com outros objetivos que não a melhor comunicação entre os pacientes e suas equipes de saúde. Qualquer análise dos dados será feita de forma anonimizada tanto para equipes de saúde como pacientes. A estrutura de armazanamento de dados segue rigorosamente as políticas de proteção de dados vigentes em nosso país, em especial à Lei Geral de Proteção de Dados Pessoais (LGPD) - Lei nº 13.709/2018.</p>
    <p>Quando o registro de pacientes é feito no aplicativo os dados como nome, endereço eletrônico, idade, CEP, diagnóstico assim como sintomas e outras informações médicas são protegidas e encriptografadas segindo padrões atuais de segurança. Da mesma forma os dados da equipe de saúde como o CRM, endereço eletrônico ou qualquer outra informação será mantida de forma sigilosa.</p>
    <p>Este aplicativo não coleta informação sobre sua localização. Este aplicativo não veicula nenhum tipo de propaganda nem direciona os dados pore le coletados para qualquer tipo de serviço de terceiros.</p>
    <p>Apenas dados agregados e anonimados são analisados para que possamos realizar melhorias no funcionamento do aplicativo. </p>
    <p>
      O usuário pode interromper o uso do aplicativo desinstalando o mesmo de seu aparelho móvel. O usuário também pode solicitar o cancelamento do uso por envio de e-mail para <span v-if="termsApp">usuario@lilasaude.com.br</span> <a
        v-else
        href="mailto:usuario@lilasaude.com.br"
      >usuario@lilasaude.com.br</a>
    </p>
    <p>
      Caso o usuário cancele o uso do aplicativo e deseje que seus dados sejam deletados do armazanamento, basta enviar e-mail para <span v-if="termsApp">usuario@lilasaude.com.br</span> <a
        v-else
        href="mailto:usuario@lilasaude.com.br"
      >usuario@lilasaude.com.br</a> solicitando o descarte de seus dados. Do contrário estes serão mantidos por 24 meses e a partir deste prazo apenas de forma agregada.
    </p>
    <p>Quanto à segurança dos dados, estamos compromissados em manter a confidencialidade de todas as informações coletadas. Oferecemos salvaguardas físicas, eletrônicas, e de procedimentos para proteger as informações por nós armazenadas e processadas. Apenas técnicos envolvidos com a operação, desenvolvimento e melhorias do Sistema podem acessar a estrutura de dados. Apesar de todo o empenho e esforço sabemos que nenhum Sistema pode impedir todas as violações de segurança, portanto este é um processo contínuo de melhorias que faz parte da estrutura do aplicativo.</p>
    <p>Esta política de privacidade pode ser atualizada periodicamente para atender demandas legais e melhorias no sistema. Neste caso informaremos através de e-mail ou por mensagem de texto.</p>
    <p>Ao usar este aplicativo, você concorda com essa política de privacidade.</p>
    <p>
      Caso tenha alguma dúvida envie mensagem para <span v-if="termsApp">usuario@lilasaude.com.br</span> <a
        v-else
        href="mailto:usuario@lilasaude.com.br"
      >usuario@lilasaude.com.br</a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    termsApp: {
      required: false,
      type: Boolean,
      defaul: false
    }
  }
};
</script>

<style>
    @import '~@assets/css/views/terms.css';
</style>