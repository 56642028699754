<template>
  <lila-login-template>
    <template #mainContent>
      <form
        class="login-form full-width"
        @submit.prevent="submitForm()"
      >
        <h1 class="title">
          Resetar Senha do aplicativo
        </h1>

        <lila-message :alert-data="alert" />

        <div v-if="!passwordChanged">
          <lila-input
            v-model="v.data.password.$model"
            placeholder="Digite a senha"
            type="password"
            :validation="v.data.password"
          />

          <lila-input
            v-model="v.data.confirmPassword.$model"
            placeholder="Confirme a senha"
            type="password"
            :validation="v.data.confirmPassword"
          />

          <lila-button
            type="submit"
            title="Confirmar"
            class="submit-buttons"
            :loading="loading"
          />
        </div>
      </form>
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";

import Input from "@components/shared/fields/Input.vue";
import Button from "@components/shared/button/Button.vue";
import Message from "@components/shared/message/Message.vue";

import { required, minLength, maxLength, sameAs } from "@vuelidate/validators";

const checkSpaces = function(value){
  return !/\s/.test(value);
};

export default {
  components: {
    "lila-input": Input,
    "lila-button": Button,
    "lila-message": Message,
    "lila-login-template": LoginTemplate
  },
  data() {
    return {
      data: {
        password: "",
        confirmPassword: "",
        token: this.$route.params.token
      },
      loading: false,
      passwordChanged: false,
      alert: {
        message: "",
        type: "",
        show: false
      }
    };
  },
  validations() {
    return {
      data: {
        password: {
          required,
          checkSpaces,
          minLength: minLength(6),
          maxLength: maxLength(15)
        },
        confirmPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(15),
          sameAsPassword: sameAs(this.data.password)
        }
      }
    };
  },
  methods: {
    submitForm(){
      this.alert.show = false;

      this.v.$touch();

      if(this.v.$invalid) return;

      this.loading = true;

      this.$axios({
        url: "api/account/reset-password",
        baseURL: this.$api_app,
        method: "PUT",
        data: this.data
      })
        .then(() => {
          this.passwordChanged = true;
          this.$Utilities.controlAlert(null, this.alert, "passwordChanged");
        })
        .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, "danger"))
        .finally(() => this.loading = false);
    }
  }
};
</script>

<style></style>
