<template>
  <div class="main">
    <section class="section terms-doc">
      <lila-terms-doc :terms-app="true" />
    </section>
  </div>
</template>

<script>
import TermsDoc from "@components/shared/lila/TermsDoc.vue";

export default {
  components: {
    "lila-terms-doc": TermsDoc
  }
};
</script>

<style>
    @import '~@assets/css/views/terms.css';
</style>