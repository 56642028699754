<template>
  <lila-login-template>
    <template #mainContent>
      <div class="login-form full-width">
        <h1 class="title">
          Complete seu Cadastro
        </h1>
                
        <p>Nos ajude a personalizar a sua experiência no APP.</p>

        <lila-button 
          type="route" 
          path="basicInformation"
          title="Completar Cadastro" 
          class="submit-buttons"
          :second-button="{type: 'routeText', route: 'patientTickets', title: 'Continuar mais tarde', class: 'lilac-text'}"
        />
      </div>         
    </template>
  </lila-login-template>
</template>

<script>
import LoginTemplate from "@components/template/LoginTemplate.vue";
import Button from "@components/shared/button/Button.vue";

export default {
  components: {
    "lila-button": Button,
    "lila-login-template": LoginTemplate
  },
  created(){
    if(this.$Session.get(this.$userSessionName).profile == "doctor"){
      this.$Utilities.checkFullRegistration();
    }else{
      this.$routes.push({name: "patientTickets"});
    }
  }
};
</script>

<style></style>