<template>
  <div class="chart-js-container">
    <Line
      ref="chart"
      v-bind="attrs"
    />
  </div>
</template>

<script setup>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

import { ref, useAttrs } from "vue";
const attrs = useAttrs();
const chart = ref();

function update() {
  return chart.value.chart.update();
}

defineExpose({
  chart,
  update
});
</script>

<style>
.chart-js-container {
  width: 800px;
}
</style>
