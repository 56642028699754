import { Utilities } from "./Utilities.js";
import Axios from "axios";


export const HTTPUtilities = {

  async post(app, route, data, successCallback, alert, customBaseUrl = null) {

    const apiInstance = customBaseUrl 
    ? Axios.create({ baseURL: customBaseUrl }) 
    : app.$axios;

    await apiInstance({
      method: "POST",
      url: route,
      data: data,
      headers: { "Authorization": `bearer ${app.$Session.get(app.$userSessionName).token.Value}` }
    })
      .then(successCallback)
      .catch(error => { console.log("B " + JSON.stringify(error)); this.handleError(error, alert);});

  },

  async put(app, route, data, successCallback, alert, customBaseUrl = null) {

    const apiInstance = customBaseUrl 
    ? Axios.create({ baseURL: customBaseUrl }) 
    : app.$axios;

    await apiInstance({
      method: "PUT",
      url: route,
      data: data,
      headers: { "Authorization": `bearer ${app.$Session.get(app.$userSessionName).token.Value}` }
    })
      .then(successCallback)
      .catch(error => { this.handleError(error, alert);});

  },


  async get(app, route, successCallback, alert, customBaseUrl = null) {


    const apiInstance = customBaseUrl 
    ? Axios.create({ baseURL: customBaseUrl }) 
    : app.$axios;

    await apiInstance({

      method: "GET",
      url: route,
      headers: { "Authorization": `bearer ${app.$Session.get(app.$userSessionName).token.Value}` }
    })
      .then(successCallback)
      .catch(error => { this.handleError(error, alert);});
  },

  async delete(app, route, successCallback, alert, customBaseUrl = null) {

    const apiInstance = customBaseUrl 
    ? Axios.create({ baseURL: customBaseUrl }) 
    : app.$axios;

    await apiInstance({
      method: "DELETE",
      url: route,
      headers: { "Authorization": `bearer ${app.$Session.get(app.$userSessionName).token.Value}` }
    })
      .then(successCallback)
      .catch(error => { this.handleError(error, alert);});

  },


  handleError(error,alert){
    
    Utilities.verifyToken(error.response.status);
        if (alert) {
          alert.show = true;
          alert.type = "danger";
          alert.message = (error.response && error.response.data && error.response.data.StatusCode == 400 ? error.response.data.Message || "Erro interno." : "Erro interno.");
        }
  }
  
};

export default function (app) {
  return app.config.globalProperties.$HTTPUtilities = HTTPUtilities;
}