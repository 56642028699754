<template>
  <div 
    v-if="modelValue" 
    class="modal modal-outcome"
  >
    <div class="modal-content">
      <h1>
        Desfechos
      </h1>

      <lila-message :alert-data="alertData" />
     
      <div 
        v-if="showOutcomeForm"
        class="outcome-form" 
      >
        <h3>
          Adicione um desfecho
        </h3>
        
        <section class="form-content">
          <lila-select
            v-model="selectedOption"
            placeholder="Selecione"
            :options="optionsList"
            class="select-outcome"
            @custom-change-event="selectedOption = parseInt($event)"
          />
          <lila-textarea 
            v-if="showCustomOption" 
            v-model="customOption"  
            placeholder="Por favor especifique o desfecho"
            class="textarea-outcome"
          />

          <button 
            class="button modal-save-btn" 
            @click="addOutcome"
          >
            Salvar
          </button>
        </section>
      </div>
      
      <div 
        v-for="(outcome, index) in outcomes" 
        :key="index"
        class="outcome-item" 
      >
        <div class="outcome-item-content">
          <div v-if="editingIndex !== index">
            <h3>
              {{ outcome.name }}
            </h3>

            <div class="icon-container">
              <button 
                class="delete-button" 
                @click="deleteOutcome(index)"
              >
                <i class="fas fa-trash" />
              </button>

              <button 
                class="edit-button" 
                @click="startEditing(index)"
              >
                <i class="fas fa-pen" />
              </button>
            </div>
          </div>

          <div v-if="editingIndex === index">
            <lila-select
              v-model="selectedOption"
              placeholder="Selecione"
              :options="optionsList"
              class="select-outcome"
              @custom-change-event="selectedOption = parseInt($event)"
            />
            <lila-textarea 
              v-if="showCustomOption" 
              v-model="customOption"  
              placeholder="Por favor especifique o desfecho"
              class="textarea-outcome"
            />
            <button 
              class="button modal-save-btn" 
              @click="saveEdit"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button 
          class="button modal-btn" 
          @click="openOutcomeForm"
        >
          Adicionar novo desfecho
        </button>

        <button 
          class="button modal-btn"
          @click="createOutcomes"
        >
          Confirmar encerramento do chamado
        </button>
      </div>

      <button 
        class="close-btn delete" 
        @click="closeAndResetModal"
      />
    </div>
  </div>
</template>


<script setup>
import { ref, computed, inject } from "vue";
import axios, { userSessionName } from "../../js/axios";
import { Session } from "../../plugins/Session";
import { Utilities } from "../../plugins/Utilities.js";
import LilaSelect from "@components/shared/fields/Select.vue";
import LilaTextarea from "@components/shared/fields/Textarea.vue";
import LilaMessage from "../shared/message/Message.vue";

const p = defineProps({
  modelValue: Boolean,
  ticketId: null,
  alert: null
});

const emit = defineEmits(["update:modelValue"]);

const showOutcomeForm = ref(false);
const outcomes = ref([]);
const selectedOption = ref("");
const customOption = ref("");
const editingIndex = ref(-1);
const editing = ref(false);
const optionsList = ref([]);
const controlAlertMessage = inject("alert-message");
const alertData = ref({
  show: false,
  type: "",
  message: ""
});


const showCustomOption = computed(() => selectedOption.value === 0);

function openOutcomeForm() {
  showOutcomeForm.value = true;
}

function addOutcome() {
  if (selectedOption.value === 0) {
    outcomes.value.push({ _id: 0, name: customOption.value });
  } else {
    const outcome = optionsList.value.find((option) => option._id === selectedOption.value);
    if (outcome) {
      outcomes.value.push(outcome);
    }
  }
  selectedOption.value = "";
  customOption.value = "";
  showOutcomeForm.value = false;
}


function startEditing(index) {
  editing.value = true;
  editingIndex.value = index;
  const outcome = outcomes.value[index];
  if (outcome._id === 0) {
    selectedOption.value = 0;
    customOption.value = outcome.name;
  } else {
    selectedOption.value = outcome._id;
  }
}

function updateOutcome() {
  if (selectedOption.value === 0 && customOption.value !== "") {
    outcomes.value[editingIndex.value] = { _id: 0, name: customOption.value };
  }
  else if (selectedOption.value !== 0) {
    const outcome = optionsList.value.find((option) => option._id === selectedOption.value);
    if (outcome) {
      outcomes.value[editingIndex.value] = outcome;
    }
  }
  selectedOption.value = "";
  customOption.value = "";
  editingIndex.value = -1;
}

function saveEdit() {
  updateOutcome();
  editing.value = false;
  showOutcomeForm.value = false;
}

function deleteOutcome(index) {
  outcomes.value.splice(index, 1);
}

function resetOutcomes() {
  outcomes.value = [];
  selectedOption.value = "";
  customOption.value = "";
  showOutcomeForm.value = false;
  editingIndex.value = -1;
}

function closeAndResetModal() {
  emit("update:modelValue", false);
  resetOutcomes();
}

async function getOutcomeTypes() {
  try {
    const response = await axios.get(
      "/api/outcome",
      {headers: {"Authorization": `bearer ${Session.get(userSessionName).token.Value}`}}
      );
      let options = response.data.map((item) => ({ 
        _id: item.Id, 
        name: item.Name
      }));
    
      options.sort((a, b) => {
        if (a.name === "Outros") return 1;
        if (b.name === "Outros") return -1;
      return 0;
    });
    
    optionsList.value = options;
    
  } catch (error) {
    console.error(error);
    Utilities.verifyToken(error.response.status);
    Utilities.controlAlert(error.response.data, alertData.value, "danger", "Não foi possível recuperar os Desfechos");
  }
}

getOutcomeTypes();

async function createOutcomes() {
  let formatOutcome = {
    outcomes: outcomes.value.map(item => {
      let outcome = {
        OutcomeTypeId: item._id
      };

      if (item._id === 0) {
        outcome.description = item.name;
      }

      return outcome;
    })
  };
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.post(
      `/api/ticket/${p.ticketId}/outcomes`, 
      formatOutcome,
      {headers: {"Authorization": `bearer ${Session.get(userSessionName).token.Value}`}}
    );
    
    closeAndResetModal();

    Utilities.controlAlert(null, controlAlertMessage.value, "success", "Chamado encerrado com sucesso.");

  } catch (error) {
    console.error(error);
    if (error.response) {
        Utilities.verifyToken(error.response.status);
        Utilities.controlAlert(error.response.data, controlAlertMessage.value, "danger");
    } else if (error.request) {
        Utilities.controlAlert(null, controlAlertMessage.value, "danger", "O servidor não responde");
    } else {
        Utilities.controlAlert(error.message, controlAlertMessage.value, "danger");
    }
  }
}

</script>

<style>
.modal-outcome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-outcome h1 {
  font-size: 30px;
  font-weight: 700;
  color: #4f3f82;
}

.modal-outcome .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background:#f2f2f2;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 700px;
  height: 750px;
  overflow: auto;
}

.modal-outcome .form-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.modal-outcome .outcome-form {
  background: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-outcome .outcome-form h3 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #4F3F82;
  display: block;
  margin-bottom: 20px;
}

.modal-outcome .select-outcome {
  width: 100%;
  box-shadow: 0px 1px 6px #00000024;
  border-radius: 20px;
  margin: 0;
} 

.modal-outcome .textarea-outcome {
  width: 100%;
  box-shadow: 0px 1px 6px #00000024;
  border-radius: 8px;
}

.modal-outcome .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px; 
  margin-top: auto;
}

.modal-outcome .modal-btn,
.modal-outcome .modal-save-btn {
  color: #9a397d;
  border: 2px solid #9a397d;
  border-radius: 10px;
  background-color: white;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  width: 400px;
  height: 35px;
  margin: 0;
}

.modal-outcome .modal-save-btn {
  width: 100px;
}

.modal-outcome .modal-btn:hover,
.modal-outcome .modal-save-btn:hover {
  background-color: #9a397d;
  color: white;
}

.modal-outcome .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-outcome .outcome-item {
  background: white;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-outcome .outcome-item h3 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #0c0a14;
  display: inline-block;
}

.modal-outcome .outcome-item .icon-container {
  display: flex;
  gap: 10px;
  float: right;
}

.modal-outcome .outcome-item .edit-button,
.outcome-item .delete-button {
  background: transparent;
  border: none;
  color: #4F3F82;
  cursor: pointer;
}

.modal-outcome .outcome-item .delete-button:hover, 
.outcome-item .edit-button:hover  {
  color: black;
}
</style>
